export * from "./main"
export * from "./feedback"
export * from "./about-app"
// export * from "./countries"
export * from "./partners"
export { default as BaseLayout } from "./base-layout.vue"
export { default as Layout } from "./layout.vue"
export { default as FooterDefault } from "./footer-default.vue"
export { default as HeaderMain } from "./header-main.vue"
export { default as HeaderStatic } from "./header-static.vue"
export { default as LayoutFooter } from "./layout-footer.vue"
export { default as LayoutHeader } from "./layout-header.vue"
export { default as SectionEnter } from "./section-enter.vue"
export { default as SectionForgetPassword } from "./section-forget-password.vue"
