import SchedulePointModel from "./SchedulePointModel";
import { CropImageModel } from "./CropImageModel";
import MarkerMapModel from "../models/MarkerMapModel";

export default class MapPointMarkerModel extends MarkerMapModel {
  point:{DetailUrl:string,Title:string,Description:string}= {DetailUrl:"",Description:"",Title:""};
  Id: number = 0;
  Title?: string;
  Description?: string;
  Number: number = 0;
  Latitude: number = 0;
  Longitude: number = 0;
  DetailUrl: string;
  PreviewImages?: Array<CropImageModel>;
  ImageId?: number;
  Count?: number;
  SchedulePoint?: SchedulePointModel;
  constructor(obj?: MapPointMarkerModel) {
    super(
      new MarkerMapModel({
        lat: ((obj as any).Position.Latitude ?? 0).toString(),
        lng: ((obj as any).Position.Longitude ?? 0).toString(),
      })
    );
    console.log("-------------------",obj)
    Object.assign(this, obj);
  }

}
