<!-- @format -->

<template>
	<popup class="form-popup">
		<popup-title>Смена e-mail</popup-title>
		<pos-input-label
			:ref="refEmai4"
			:label="$t('feedback.Enter e-mail')"
			v-model="emai4"
			htmlClass="form-item"
			name="email4"
			type="email"
			placeholder="example@gmail.com"
			:required="true"
			:regex="email4Regex"
		>
			<svg width="12" height="12">
				<use xlink:href="assets/img/svg-sprite.svg#email-input-icon"></use>
			</svg>
		</pos-input-label>
		<form-button @click="next()">Сменить</form-button>
	</popup>
</template>

<script lang="ts" setup>
import { Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const isClickedContinue = ref(false);
const email4Regex: Ref<RegExp> = ref(
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const refEmai4 = ref();
const email4 = ref("");
const router = useRouter();

const next = () => {
	isClickedContinue.value = true;
	//если проходит валидация
	if (!refEmai4.value.checkValidation()) {
		return;
	}
	router.push({ name: "countries" });
};
</script>

<style lang="css" scoped></style>
