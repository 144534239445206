

import moment from "moment";
import MapFilterModel from '../models/MapFilterModel';


export class RouteFilterModel extends MapFilterModel {
  constructor(obj?: Partial<RouteFilterModel>) {
    super(obj);
    if (obj) Object.assign(this, obj);
  }

  Status: string[] = [];
  SearchAuthor?: string;
  Transmission: string = "-1";
  Type: string = "-1";
  Search?: string;
  AuthorId?: number;
  Dates?: Array<Date>;
  OrderByDateStatusUpdateDesc: boolean;
  OrderByIdDesc: boolean;
  OrderByTitleDesc: boolean;
  IsPrivate?: boolean;
  /**
   * * Включить маршруты с категориями
   */
  IncludeCategories?: boolean;
}
