<!-- @format -->

<template>
	
	<section class="main">
		<a id="main-section"></a>
		<main-image />
		<div class="main-flex container-fluid">
			<descr-block class="mb">
				<main-title class="mt-5" />
				<!-- <subtitle class="ta-1000 main-subtitle subtitle-media-md subtitle-small"
          >Пандемия не повод сидеть дома!</subtitle
        > -->
				<button-group />
			</descr-block>
			<div class="token-wrap">
				<img v-if="locale === 'ru'" src="@assets/img/artifact-ru.png" alt="Russian Image">
				<img v-else-if="locale === 'en'" src="@assets/img/artifact-en.png" alt="English Image">
				<img v-else-if="locale === 'lv'" src="@assets/img/artifact-lv.png" alt="English Image">
				<div class="token">
					<div class="token-bg"></div>
					<div class="token-text"><strong>{{ $t("homepage.Valuable NFT Artifacts") }}</strong></div>
				</div>
			</div>
		</div>

		<div class="ellipse-yellow">
			<img src="@assets/img/main/Ellipse-yellow.svg" alt="" />
		</div>
	</section>
</template>

<script lang="ts" setup>
import Vue, { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { MainImage, MainTitle, DescrBlock, ButtonGroup } from "@/components";
const locale = ref(localStorage.getItem("lang") || "en");


const updateLocale = () => {
	locale.value = document.documentElement.getAttribute('data-locale') || 'en';
};
onMounted(() => {
	const observer = new MutationObserver(updateLocale);
	observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-locale'] });

	// Сохраняем observer в переменную, чтобы можно было отключить его при размонтировании компонента
	onBeforeUnmount(() => {
		observer.disconnect();
	});
});

</script>

<style lang="scss" scoped>
.token-wrap {
	position: absolute;
	z-index: 1000;
	// left: 95px;
	bottom: -240px;

	.token {
		position: relative;

		.token-bg {
			position: absolute;
			top: -40px;
			left: -80px;
			filter: blur(5px);
			width: 635px;
			height: 50px;
			z-index: 99;
		}

		.token-text {
			// background: #7465fd2a;
			text-shadow: 0px 4px 4px #00000040;
			background: var(--background-color-strong);
			color: var(--text-color-first);
			border-radius: 8px;
			position: absolute;
			top: -40px;
			// left: -80px;
			z-index: 100;
			width: 625px;
			text-transform: uppercase;
			// color: #3da6a7;
			font-size: 48px;
			font-weight: 500;
			line-height: 42px;
			// text-align: left;
			// padding-left: 15px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.main-flex {
		flex-direction: column;
	

		.token-wrap {
			position: unset;
			margin-top: 30px;
			// margin-left: -300px;
			display: flex;
			align-items: center;
			flex-direction: column;

			.token {
				// position: unset;
				display: flex;

				.token-bg {
					// left: 0px;
				}
			}

			.token-text {
				position: inherit;
				left: 0px;
				font-size: 30px;
				width: 390px;
				line-height: 36px;
			}
		}
	}
}

.main {
	min-height: 800px;
	position: relative;
	background-size: contain;
	// display: flex;
	// overflow: hidden;
	// align-items: center;
	// justify-content: flex-start;
	/* background-color: var(--background-color); */
	/* background: url('/assets/img/main/main-bg-3.jpg') no-repeat;
    background-position: right 0 center;
    background-size: contain; */
}

.main-flex {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	
}

.ellipse-violet {
	right: 0;
}

.ellipse-yellow {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.ellipse-yellow img {
	max-width: 100%;
	height: auto;
}

@media all and (max-width: 1000px) {
	.main {
		display: block;
		min-height: auto;
	}

	.main-flex {
		padding-bottom: 70px;
		justify-content: center;
	}

	.ellipse-yellow {
		top: inherit;
		bottom: -20px;
		left: -150px;
	}
}

@media all and (max-width: 480px) {
	.ellipse-yellow {
		bottom: -80px;
	}
}

/* @media all and (min-width: 1920px) {
    .main { min-height: calc((100vw + 100000px)/(1920 + 100000) * (904 + 20000) - 20000px); overflow: hidden; }
    .main-flex { max-width: 70%; }

} */

@media all and (min-width: 1920px) {
	.main {
		overflow: hidden;
	}
}
</style>
