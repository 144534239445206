<!-- @format -->

<template>
	<layout>
		<fullpage>
			<header-static />

			<static-title>Страны</static-title>
			<section-map />

			<footer-default />
		</fullpage>

		<overlay />
		<burger-menu />
		<section-enter />
		<section-forget-password />

		<popup>
			<popup-img><img src="@assets/img/check.svg" alt="Ура" /></popup-img>
			<popup-text
				>Ваш имейл подтвержден, авторизуйтесь в приложении Artifactum</popup-text
			>
		</popup>
	</layout>
</template>

<script lang="ts" setup>
import Vue, { onMounted } from "vue";

onMounted(() => {
	var mobileMenu = document.querySelector(".mobile-menu");
	var mobileMenuIcon = document.querySelector(".mobile-menu-icon");
	var overlay = document.querySelector(".overlay");
	var closeIcon = document.querySelectorAll(".close-icon");
	var closePopup = document.querySelectorAll(".popup-close");
	var fullpage = document.querySelector(".fullpage");
	var enterBtn = document.querySelectorAll(".enter-button");
	var enterPa = document.querySelector(".enter-pa");
	var overlayModal = document.querySelector(".overlay-modal");
	var modal = document.querySelector(".modal-window");
	var wrapModal = document.querySelector(".wrap-modal");
	var openModal = document.querySelectorAll(".modal-button");
	var closeModal = document.querySelector(".close-modal");
	var mobileLink = document.querySelectorAll(".mobile-list-item");
	var pass = document.querySelector('input[type="password"]');
	var eye = document.querySelector(".eye");
	var forgetPassPopup = document.querySelector(".section-forget-password");
	var forgetPassLink = document.querySelectorAll(".forget-password");

	mobileMenuIcon.addEventListener("click", function () {
		if (!mobileMenuIcon.classList.contains("active")) {
			mobileMenuIcon.classList.add("active");
		} else {
			mobileMenuIcon.classList.remove("active");
		}

		if (!mobileMenu.classList.contains("active")) {
			mobileMenu.classList.add("active");
			overlay.classList.add("show");
			fullpage?.classList.add("hide");
		} else {
			mobileMenu.classList.remove("active");
			overlay.classList.remove("show");
			fullpage?.classList.remove("hide");
		}
	});

	for (let i = 0; i < enterBtn.length; i++) {
		enterBtn[i].addEventListener("click", function () {
			if (!enterPa.classList.contains("active")) {
				enterPa.classList.add("active");
				overlay.classList.add("show");
				fullpage.classList.add("hide-enter");
				fullpage?.classList.remove("hide");
				mobileMenu.classList.remove("active");
			} else {
				enterPa.classList.remove("active");
				overlay.classList.remove("show");
				fullpage?.classList.remove("hide-enter");
			}
		});
	}

	for (let i = 0; i < mobileLink.length; i++) {
		(mobileLink[i] as any).onclick = function () {
			if (!mobileLink[i].classList.contains("active")) {
				mobileMenu.classList.remove("active");
				mobileMenuIcon.classList.remove("active");
				overlay.classList.remove("show");
				fullpage?.classList.remove("hide");
			}
		};
	}

	for (let i = 0; i < closeIcon.length; i++) {
		closeIcon[i].addEventListener("click", function () {
			mobileMenu.classList.remove("active");
			mobileMenuIcon.classList.remove("active");
			overlay.classList.remove("show");
			fullpage?.classList.remove("hide");
			fullpage?.classList.remove("hide-enter");
			enterPa.classList.remove("active");
			forgetPassPopup.classList.remove("active");
		});
	}

	for (let i = 0; i < closePopup.length; i++) {
		closePopup[i].addEventListener("click", function () {
			modal.classList.remove("show");
			overlayModal.classList.remove("show");
			wrapModal.classList.remove("show");
		});
	}

	for (let i = 0; i < forgetPassLink.length; i++) {
		forgetPassLink[i].addEventListener("click", function () {
			if (!forgetPassPopup.classList.contains("active")) {
				forgetPassPopup.classList.add("active");
				overlay.classList.add("show");
				fullpage.classList.add("hide-enter");
				fullpage?.classList.remove("hide");
				mobileMenu.classList.remove("active");
			} else {
				forgetPassPopup.classList.remove("active");
				overlay.classList.remove("show");
				fullpage?.classList.remove("hide-enter");
			}
		});
	}

	overlay.addEventListener("click", function () {
		overlay.classList.remove("show");
		mobileMenu.classList.remove("active");
		mobileMenuIcon.classList.remove("active");
		fullpage?.classList.remove("hide");
		fullpage?.classList.remove("hide-enter");
		enterPa.classList.remove("active");
		forgetPassPopup.classList.remove("active");
	});

	document.body.onkeydown = function (e) {
		if (e.keyCode == 27) {
			mobileMenu.classList.remove("active");
			mobileMenuIcon.classList.remove("active");
			overlay.classList.remove("show");
			fullpage?.classList.remove("hide");
			enterPa.classList.remove("active");
			fullpage?.classList.remove("hide-enter");
			modal.classList.remove("show");
			overlayModal.classList.remove("show");
			wrapModal.classList.remove("show");
			forgetPassPopup.classList.remove("active");
		}
	};

	for (let i = 0; i < openModal.length; i++) {
		(openModal[i] as any).onclick = open;
	}

	(openModal as any).onclick = open;
	(overlayModal as any).onclick = close;

	function open() {
		modal.classList.add("show");
		overlayModal.classList.add("show");
		wrapModal.classList.add("show");
	}

	function close() {
		modal.classList.remove("show");
		overlayModal.classList.remove("show");
		wrapModal.classList.remove("show");
	}
});
</script>
