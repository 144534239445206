import MarkerMapModel from './MarkerMapModel';

export default class MapFilterModel {

  constructor(filter?: Partial<MapFilterModel>) {
    if (filter) Object.assign(this, filter);
  }
  
  /**
   * * Масштабирование карты
   */
  Zoom: number = 12;

  /**
   * * Координать к северо-востоку
   */
  NorthEast: MarkerMapModel = new MarkerMapModel({
    lat: "47.034098593479314",
    lng: "30.03250122070313",
  });

  /**
   * * Координать к юго-западу
   */
  SouthEast: MarkerMapModel = new MarkerMapModel({
    lat: "46.65839116531205",
    lng: "29.18930053710938",
  });
}
