export class PaginationModel {
  constructor(obj?: PaginationModel) {
    if (obj) Object.assign(this, obj);
  }
  /**
   * Текущая страница
   */
  Current: number = 1;
  /**
   * Количество сущностей за один запрос
   */
  Size: number = 10;
  /**
   * Общее количество
   * */
  Count: number = 0;
}
