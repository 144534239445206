<!-- @format -->

<template>
	<div class="form-item" v-bind:class="[{ error: showValidation }, htmlClass]">
		<label :for="name" v-if="label">{{ label }}</label>
		<slot />
		<pos-input
			:type="type"
			ref="inputRef1"
			:value="value"
			@input="onInput"
			@changeValue="onChangeValue"
			:name="name"
			:placeholder="placeholder"
			:required="required"
			:regex="regex"
			:password="password"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

const props = defineProps({
	value: { type: String, required: true, default: "" },
	type: { type: String, required: true },
	name: { type: String, required: false, default: undefined },
	placeholder: { type: String, required: false, default: undefined },
	regex: { type: RegExp, required: false, default: undefined },
	password: { type: Boolean, default: false },
	required: { type: Boolean, default: false },
	htmlClass: { type: String },
	label: { type: String },
	isShowPassword: { type: Boolean, default: false },
});

const emit = defineEmits<{
	changeValue: [string];
	input: [string];
	"update:value": [string];
}>();

const showValidation = ref(false);
const innerValue = ref("");
const inputRef1 = ref();

onMounted(() => {
	innerValue.value = props.value;
});

const onChangeValue = (value: string) => {
	emit("changeValue", value);
};

/**
 * Проверка валидности поля + отображение невалидности
 */
function checkValidation(): boolean {
	const inputComponent = inputRef1.value;
	if (inputComponent.checkValidation()) {
		showValidation.value = false;
	} else {
		showValidation.value = true;
	}
	return showValidation.value;
}

/**
 * Проверка валидности поля
 */
function isValid(): boolean {
	const inputComponent = inputRef1.value;
	if (inputComponent && typeof inputComponent.isValid === "function") {
		return inputComponent.isValid();
	}
	console.warn("isValid method not found");
	return false;
}

const onInput = (newValue: string) => {
	innerValue.value = newValue;
	emit("update:value", innerValue.value);
	checkValidation();
};
defineExpose({
	isValid,
	checkValidation,
});
</script>

<style lang="css">
.form-item {
	margin-bottom: 17px;
	position: relative;
	width: 100%;
}

.form-item svg {
	position: absolute;
	left: 14px;
	top: 40px;
	fill: #5d58b7;
}

.form-item .eye,
.form-item .hide,
.form-item .eye2,
.form-item .hide2,
.form-item .eye3,
.form-item .hide3,
.form-item .eye4,
.form-item .hide4,
.form-item .eye5,
.form-item .hide5 {
	position: absolute;
	right: 15px;
	left: inherit;
	top: 40px;
	fill: #9998aa;
	cursor: pointer;
}

.form-item .eye.none,
.form-item .hide.none,
.form-item .eye2.none,
.form-item .hide2.none,
.form-item .eye3.none,
.form-item .hide3.none,
.form-item .eye4.none,
.form-item .hide4.none,
.form-item .eye5.none,
.form-item .hide5.none {
	display: none;
}

.form-item label {
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #9493a9;
	display: inline-block;
}

.form-item input {
	padding-left: 36px;
	padding-right: 10px;
	min-height: 46px;
	width: 100%;
	background: #e7e6f9;
	border-radius: 5px;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: black;
}

.form-item.error input {
	background: #c1c0db;
	color: black;
	border: 1px solid #b10444;
}

.form-item.error svg {
	/* fill: #B10444; */
	fill: #6d69c9;
}

.form-item.error ::-webkit-input-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error :-moz-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error ::-moz-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error :-ms-input-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error ::-ms-input-placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: black;
	opacity: 1;
	/* Firefox */
	font-weight: 500;
}

.form-item.error :-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: black;
	font-weight: 500;
}

.form-item.error ::-ms-input-placeholder {
	/* Microsoft Edge */
	color: black;
	font-weight: 500;
}
</style>
