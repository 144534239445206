<!-- @format -->

<template>
	<div class="ui-theme">
		<div class="theme-wrap" @click="changeTheme">
			<div v-if="isLight" class="theme light">Light</div>
			<div v-else class="theme dark">Dark</div>
		</div>
	</div>
</template>

//
<script setup lang="ts">
import { onMounted, ref } from "vue";

const isLight = ref(true);
onMounted(() => {
	const res = localStorage.getItem("theme");

	if (res && res == "dark") {
		isLight.value = false;
	}
	setTheme(isLight.value);
});
const setTheme = (isLightTheme: boolean = true) => {
	document.documentElement.setAttribute("data-theme", isLightTheme ? "light" : "dark");
	localStorage.setItem("theme", isLightTheme ? "light" : "dark");
};
const changeTheme = () => {
	isLight.value = !isLight.value;
	setTheme(isLight.value);
};
</script>

<style lang="scss">
.ui-theme {
	.theme-wrap {
		cursor: pointer;

		.theme {
			font-weight: 900;
			font-size: 0.7em;
		}
	}
}
</style>
