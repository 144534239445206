<!-- @format -->

<template>
	<router-view />
</template>
<script lang="ts" setup></script>
<style lang="less">
@import "@/assets/css/bootstrap-grid.min.css";
@import "@/assets/css/reset.min.css";
@import "@/assets/css/fonts.css";
@import "@/assets/css/style.css";
</style>
