<!-- @format -->

<template>
	<div class="terms-conditions">
		<div>
			<h1>Условия использования</h1>
			<h3><span>1. Общие положения</span></h3>
			<p><span>1.1. Описание соглашения<br />Настоящее Пользовательское соглашение (далее &ndash; "Соглашение")
					регулирует взаимоотношения между пользователем (далее &ndash; "Пользователь") и юридическим лицом ООО
					"ТирСкрипт" (далее &ndash; "Компания" или "Мы"), зарегистрированным в Приднестровской Молдавской
					Республике (ПМР), в отношении использования сервиса </span>Artifactum<span>, доступного через веб-сайт,
					мобильные приложения, а также другие платформы и устройства (далее &ndash; "Сервис").</span></p>
			<p><span>1.2. Принятие условий<br />Пользуясь Сервисом, Пользователь подтверждает, что ознакомился, понял и
					согласился с условиями настоящего Соглашения. Если Пользователь не согласен с какими-либо условиями
					Соглашения, он обязан немедленно прекратить использование Сервиса.</span></p>
			<p><span>1.3. Изменения в Соглашении<br />Компания оставляет за собой право в любое время изменять,
					дополнять или удалять отдельные положения настоящего Соглашения. Изменения вступают в силу с момента их
					публикации на Сервисе или уведомления Пользователя любым иным образом. Продолжение использования Сервиса
					после внесения изменений означает согласие Пользователя с такими изменениями.</span></p>
			<p><span>1.4. Применимость Соглашения<br />Настоящее Соглашение распространяется на все функции и услуги,
					предоставляемые через Сервис, включая, но не ограничиваясь: маршруты, квесты, поиск артефактов,
					взаимодействие с дополненной реальностью, а также использование любых виртуальных предметов и
					внутриигровой валюты, предлагаемых Компанией.</span></p>
			<p><span>1.5. Правоспособность<br />Используя Сервис, Пользователь подтверждает, что он достиг возраста,
					позволяющего заключать юридически обязывающие соглашения в соответствии с применимым законодательством,
					и что его использование Сервиса не нарушает законы его страны проживания.</span></p>
			<p><span>1.6. Местоположение и юрисдикция<br />Компания является юридическим лицом, зарегистрированным в
					соответствии с законодательством Приднестровской Молдавской Республики (ПМР). Все вопросы, связанные с
					использованием Сервиса, регулируются законодательством ПМР, если иное не предусмотрено настоящим
					Соглашением. Пользователь соглашается, что все споры будут разрешаться в судах, расположенных в
					юрисдикции ПМР.</span></p>
			<p><span>1.7. Контактная информация<br />В случае возникновения вопросов, связанных с настоящим Соглашением
					или использованием Сервиса, Пользователь может связаться с Компанией по электронной почте
					[</span>support<span>@</span>artifactum<span>.</span>io<span>].</span></p>
			<h3><span>2. </span><span><strong>Описание сервиса</strong></span></h3>
			<p><span>2.1. Сервисы и услуги, предоставляемые </span>Artifactum<span><br />Сервис </span>Artifactum<span>
					представляет собой платформу, предназначенную для создания, предоставления и монетизации маршрутов и
					квестов с использованием элементов дополненной реальности (далее &ndash; "</span>AR<span>"). Сервис
					позволяет Пользователям выбирать и проходить маршруты, разрабатывать собственные маршруты и квесты, а
					также взаимодействовать с другими пользователями и партнерами Компании.</span></p>
			<p><span>2.2. Уникальные маршруты и квесты<br />Пользователи могут выбирать маршруты по своим интересам,
					используя встроенные фильтры и рекомендации, а также проходить квесты, созданные как Компанией, так и
					другими пользователями. Каждый маршрут или квест может включать живописные места, задания, загадки и
					другие элементы, усиливающие опыт использования </span>AR<span>.</span></p>
			<p><span>2.3. Виртуальные артефакты и награды<br />В процессе прохождения маршрутов и выполнения квестов
					Пользователи могут находить и собирать виртуальные артефакты, которые могут предоставлять различные
					вознаграждения, включая доступ к премиальному контенту, скидки от партнеров Компании, а также
					виртуальную валюту, которую можно использовать внутри Сервиса.</span></p>
			<p><span>2.4. Монетизация контента<br />Пользователи могут создавать свои собственные маршруты и квесты, а
					также зарабатывать на них, получая вознаграждения за количество прохождений, рекламу, размещенную на
					маршрутах, и другие активности, предусмотренные Сервисом. Право на монетизацию предоставляется только
					зарегистрированным пользователям, соответствующим условиям Соглашения.</span></p>
			<p><span>2.5. Партнерская программа и подарки<br />Компания может предоставлять Пользователям специальные
					предложения и вознаграждения от своих партнеров, такие как скидки на товары и услуги, бесплатные
					посещения заведений и другие бонусы. Эти предложения могут быть привязаны к определенным маршрутам,
					артефактам или активности в Сервисе.</span></p>
			<p><span>2.6. Интерактивные функции и социальное взаимодействие<br />Сервис позволяет Пользователям
					взаимодействовать друг с другом, делиться маршрутами, комментировать и оставлять отзывы, а также
					участвовать в сообществах, связанных с определенными направлениями и интересами. Пользователи могут
					получать обратную связь и лайки на свои маршруты, что влияет на их популярность и доход.</span></p>
			<p><span>2.7. Ограничения и доступ к контенту<br />Компания оставляет за собой право ограничивать доступ к
					определенным маршрутам, квестам или функциям Сервиса на основе географического положения, возраста
					пользователя или других факторов. Компания также может предоставлять доступ к премиальному контенту
					только за оплату или выполнение определенных условий.</span></p>
			<p><span>2.8. Изменения в Сервисе<br />Компания имеет право вносить изменения в работу Сервиса, включая, но
					не ограничиваясь, добавление новых функций, модификацию существующих функций, удаление контента, а также
					изменение условий доступа и использования Сервиса. Такие изменения могут быть внесены без
					предварительного уведомления Пользователей.</span></p>
			<h3><span><strong>3. Регистрация и учетные записи</strong></span></h3>
			<p><span>3.1. Создание учетной записи<br />Для использования некоторых функций Сервиса Пользователь обязан
					создать учетную запись. Регистрация учетной записи требует предоставления определенной информации, такой
					как имя, адрес электронной почты и другие необходимые данные. Пользователь обязуется предоставить точную
					и актуальную информацию при регистрации. Регистрация несовершеннолетних пользователей возможна только с
					разрешения их законных представителей.</span></p>
			<p><span>3.2. Требования к информации профиля<br />Пользователь обязуется поддерживать актуальность данных,
					указанных в учетной записи, и своевременно обновлять их в случае изменений. Компания не несет
					ответственности за последствия, вызванные предоставлением недостоверной или устаревшей
					информации.</span></p>
			<p><span>3.3. Ответственность за безопасность учетной записи<br />Пользователь несет полную ответственность
					за сохранение конфиденциальности своих учетных данных, включая пароль и другие идентификационные данные.
					Все действия, совершенные с использованием учетной записи Пользователя, считаются произведенными самим
					Пользователем. В случае несанкционированного доступа или использования учетной записи, Пользователь
					обязуется немедленно уведомить Компанию.</span></p>
			<p><span>3.4. Право на приостановку или прекращение учетной записи<br />Компания оставляет за собой право
					приостановить или прекратить действие учетной записи Пользователя в случае нарушения настоящего
					Соглашения, незаконного использования Сервиса или других действий, которые могут нанести ущерб Компании,
					другим пользователям или третьим лицам. Прекращение учетной записи может быть осуществлено без
					предварительного уведомления Пользователя, и в этом случае доступ к Сервису и его функциям может быть
					полностью или частично ограничен.</span></p>
			<p><span>3.5. Ограничение на создание учетных записей<br />Пользователь вправе создавать и использовать
					только одну учетную запись. Создание нескольких учетных записей одним Пользователем может привести к
					приостановке или прекращению всех связанных учетных записей.</span></p>
			<p><span>3.6. Удаление учетной записи<br />Пользователь вправе в любое время удалить свою учетную запись,
					обратившись в службу поддержки Компании или воспользовавшись соответствующими функциями Сервиса. После
					удаления учетной записи все данные Пользователя могут быть необратимо утрачены, за исключением
					информации, которую Компания обязана сохранить в соответствии с законодательством.</span></p>
			<p><span>3.7. Неприкосновенность учетных записей детей<br />Компания осознает важность защиты данных
					несовершеннолетних пользователей и требует от родителей или законных представителей согласия на создание
					учетных записей для лиц, не достигших возраста совершеннолетия, в соответствии с законодательством
					страны проживания Пользователя.</span></p>
			<h3><span><strong>4. Использование сервиса</strong></span></h3>
			<p><span>4.1. Правила использования сервиса<br />Пользователь обязуется использовать Сервис в соответствии с
					настоящим Соглашением, а также действующим законодательством. Сервис предназначен исключительно для
					личного некоммерческого использования, за исключением случаев, специально оговоренных в данном
					Соглашении или прямо разрешенных Компанией.</span></p>
			<p>4.2. Ограничения использования<br />Пользователю запрещается:</p>
			<ul>
				<li>
					<p><span>Изменять, копировать, распространять, передавать, отображать, выполнять, воспроизводить,
							публиковать, лицензировать, создавать производные работы, передавать или продавать любую информацию,
							программное обеспечение, продукты или услуги, полученные через Сервис, без предварительного
							письменного согласия Компании.</span></p>
				</li>
				<li>
					<p><span>Использовать Сервис для любых незаконных или запрещенных действий, включая, но не
							ограничиваясь, передачу материалов, нарушающих права третьих лиц, включая права интеллектуальной
							собственности.</span></p>
				</li>
				<li>
					<p><span>Пытаться обойти, взломать или иным образом вмешиваться в работу Сервиса, включая, но не
							ограничиваясь, попытками получить несанкционированный доступ к системам, базам данных или серверам
							Компании.</span></p>
				</li>
				<li>
					<p><span>Использовать программное обеспечение или устройства для автоматизации взаимодействия с Сервисом
							(например, боты, скрипты).</span></p>
				</li>
				<li>
					<p><span>Заниматься сбором данных или информации о других пользователях Сервиса без их согласия,
							включая, но не ограничиваясь, попытками "фишинга", взлома, сбора личной информации.</span></p>
				</li>
			</ul>
			<p><span>4.3. Запрет на несанкционированное использование<br />Пользователь соглашается не использовать
					Сервис для передачи, распространения или хранения любых материалов, которые могут нарушать права третьих
					лиц, включать вредоносное программное обеспечение, вирусы, или иным образом нарушать работу Сервиса или
					устройств других пользователей.</span></p>
			<p><span>4.4. Виртуальное содержание и артефакты<br />Виртуальные артефакты и внутриигровая валюта,
					доступные в Сервисе, предоставляются Пользователю по лицензии и остаются собственностью Компании.
					Пользователь признает, что он не приобретает имущественных прав на виртуальные артефакты, внутриигровую
					валюту или другие элементы виртуального содержания, и что такие права являются непередаваемыми и не
					могут быть проданы, подарены или иным образом переданы другим лицам без письменного согласия
					Компании.</span></p>
			<p><span>4.5. Права на контент и отзывы пользователей<br />Пользователь, размещая любые материалы, включая,
					но не ограничиваясь, маршруты, квесты, отзывы, изображения и текст, предоставляет Компании
					неисключительное, бессрочное, безвозмездное право на использование, воспроизведение, распространение,
					модификацию, адаптацию, публикацию, перевод и создание производных работ на основе этих материалов на
					любых медиа-платформах, существующих в настоящий момент или созданных в будущем, без обязательств по
					выплате вознаграждения Пользователю.</span></p>
			<p><span>4.6. Интерактивные функции и социальное взаимодействие<br />Сервис предоставляет Пользователям
					возможность взаимодействовать друг с другом через форумы, комментарии, рейтинги и другие социальные
					функции. Пользователь соглашается соблюдать правила поведения в сообществе, включая уважение к другим
					пользователям, запрет на использование ненормативной лексики, оскорблений, угроз и других форм
					агрессивного или неподобающего поведения.</span></p>
			<p><span>4.7. Ограничения на доступ к контенту<br />Компания оставляет за собой право ограничивать доступ к
					определенным маршрутам, квестам или другим функциям Сервиса на основе географического положения,
					возраста Пользователя или других факторов, которые могут быть определены Компанией по своему
					усмотрению.</span></p>
			<p><span>4.8. Использование данных, полученных в Сервисе<br />Пользователь соглашается не использовать
					данные, полученные через Сервис, для рассылки нежелательных сообщений (спам), рекламы, мошеннических
					предложений или любых других форм навязчивой или незаконной деятельности.</span></p>
			<p><span>4.9. Ограничение доступа и прекращение использования Сервиса<br />Компания оставляет за собой право
					ограничить доступ Пользователя к Сервису, временно или навсегда, без предварительного уведомления в
					случае нарушения настоящего Соглашения или законодательства. Такое ограничение может включать блокировку
					учетной записи, ограничение использования определенных функций или полный запрет на доступ к
					Сервису.</span></p>
			<p><span><strong>5. Пользовательский контент</strong></span></p>
			<p><span>5.1. </span><span><strong>Права на пользовательский контент</strong></span><span><br />Пользователь
					сохраняет за собой право собственности на весь контент, который он создает и загружает в Сервис (далее
					&ndash; "Пользовательский контент"). Однако, размещая Пользовательский контент в Сервисе, Пользователь
					предоставляет Компании неисключительное, бессрочное, безвозмездное, передаваемое, сублицензируемое право
					на использование, копирование, изменение, распространение, публичное отображение и исполнение такого
					контента на любых медиа-платформах, существующих в настоящий момент или созданных в будущем.</span></p>
			<p><span>5.2. </span><span><strong>Лицензия на использование контента</strong></span><span><br />Размещая
					Пользовательский контент, Пользователь гарантирует, что он обладает всеми необходимыми правами на такой
					контент, включая права на интеллектуальную собственность, и что предоставление Компании лицензии на
					использование контента не нарушает прав третьих лиц. Пользователь также соглашается с тем, что Компании
					не требуется предоставлять Пользователю какой-либо компенсации за использование его контента в рамках
					Сервиса или для продвижения Сервиса.</span></p>
			<p><span>5.3. </span><span><strong>Ограничение прав на контент</strong></span><span><br />Пользователю
					запрещается размещать в Сервисе контент, который:</span></p>
			<ul>
				<li>
					<p><span>Нарушает авторские права, товарные знаки, патенты или другие права интеллектуальной
							собственности третьих лиц.</span></p>
				</li>
				<li>
					<p><span>Содержит оскорбительный, порнографический, насильственный, расистский или иной контент, который
							может быть расценен как неприемлемый или незаконный.</span></p>
				</li>
				<li>
					<p><span>Включает вирусы, вредоносное ПО или другие технологии, которые могут нанести вред Сервису,
							Компании или другим пользователям.</span></p>
				</li>
			</ul>
			<p><span>5.4. </span><span><strong>Удаление контента</strong></span><span><br />Компания оставляет за собой
					право в любое время удалить или изменить Пользовательский контент, если он нарушает настоящее
					Соглашение, права третьих лиц или законодательство, либо если такой контент считается неприемлемым для
					размещения в Сервисе. Компания также может удалить или изменить контент по запросу третьих лиц, чьи
					права были нарушены.</span></p>
			<p><span>5.5. </span><span><strong>Ответственность за пользовательский
						контент</strong></span><span><br />Пользователь несет полную ответственность за свой контент,
					размещенный в Сервисе, включая правовую ответственность за любые претензии, которые могут возникнуть в
					связи с таким контентом. Компания не несет ответственности за проверку или мониторинг контента,
					размещенного Пользователем, и не гарантирует точность, целостность или качество такого контента.</span>
			</p>
			<p><span>5.6. </span><span><strong>Модерация контента</strong></span><span><br />Компания оставляет за собой
					право, но не обязана, осуществлять мониторинг и модерацию Пользовательского контента, а также удалять
					или блокировать контент, который нарушает настоящее Соглашение или законодательство, или который, по
					мнению Компании, является неприемлемым или нежелательным.</span></p>
			<p><span>5.7. </span><span><strong>Отзывы и предложения</strong></span><span><br />Любые отзывы, комментарии
					или предложения, предоставленные Пользователем относительно улучшения или изменения Сервиса, считаются
					неконфиденциальными и могут быть использованы Компанией без ограничения и без обязательств по
					компенсации Пользователю. Пользователь соглашается, что такие отзывы и предложения могут быть
					использованы Компанией по своему усмотрению, включая внедрение в Сервисе или разработку новых продуктов
					и услуг.</span></p>
			<h3><span>6. </span><span><strong>Монетизация и вознаграждения</strong></span></h3>
			<p><span>6.1. Заработок на маршрутах и квестах<br />Пользователь имеет возможность создавать собственные
					маршруты и квесты в Сервисе, которые могут быть доступны другим пользователям за плату или бесплатно.
					Пользователь может получать вознаграждение за популярность своих маршрутов, основанное на количестве
					прохождений другими пользователями, а также за размещение рекламы на маршрутах.</span></p>
			<p><span>6.2. Вознаграждения от партнеров<br />Пользователи, создающие популярные маршруты и квесты, могут
					получать вознаграждения от партнеров Компании в виде скидок, подарков или других бонусов. Партнеры
					Компании могут предоставлять эти вознаграждения в рамках специальных акций или программ, предусмотренных
					условиями Сервиса.</span></p>
			<p><span>6.3. Условия выплат<br />Пользователю может быть начислено вознаграждение в виде внутриигровой
					валюты или реальных денег за создание популярных маршрутов и квестов, а также за размещение рекламы на
					маршрутах. Условия выплат, включая минимальные пороги для вывода средств, способы получения и другие
					финансовые детали, определяются Компанией и могут быть изменены в любое время. Пользователь обязуется
					предоставлять точную и актуальную информацию для получения выплат, включая банковские реквизиты или
					данные учетных записей в платежных системах.</span></p>
			<p><span>6.4. Налогообложение<br />Пользователь несет полную ответственность за уплату всех налогов и
					сборов, которые могут быть применимы к полученным вознаграждениям в соответствии с законодательством
					страны его проживания. Компания не несет ответственности за выполнение Пользователем своих налоговых
					обязательств и не предоставляет консультации по налоговым вопросам.</span></p>
			<p><span>6.5. Условия участия в партнерских программах<br />Пользователь, желающий участвовать в партнерских
					программах и получать вознаграждения от партнеров Компании, должен соответствовать требованиям,
					установленным Компанией. Это может включать определенные минимальные показатели по количеству
					прохождений маршрутов, оценкам пользователей или другим критериям, определяемым Компанией. Компания
					оставляет за собой право отказать Пользователю в участии в партнерских программах или приостановить его
					участие в любое время.</span></p>
			<p><span>6.6. Ответственность за контент, связанный с монетизацией<br />Пользователь, создающий контент с
					целью монетизации, несет ответственность за соответствие такого контента требованиям законодательства и
					правилам Сервиса. Контент, связанный с монетизацией, не должен нарушать авторские права, товарные знаки
					или другие права интеллектуальной собственности третьих лиц, а также не должен содержать неприемлемый
					или незаконный материал.</span></p>
			<p><span>6.7. Ограничения и прекращение выплат<br />Компания оставляет за собой право прекратить выплаты или
					приостановить участие Пользователя в монетизационных программах в случае нарушения настоящего
					Соглашения, предоставления недостоверной информации, несанкционированного использования Сервиса или иных
					действий, которые могут нанести ущерб Компании или другим пользователям.</span></p>
			<p><span>6.8. Изменения в программе вознаграждений<br />Компания имеет право изменять условия программы
					вознаграждений и монетизации, включая размер вознаграждений, условия их получения и выплаты, а также
					способы начисления вознаграждений. Такие изменения вступают в силу с момента их публикации на Сервисе
					или уведомления Пользователя и распространяются на всех участников программы.</span></p>
			<p><span><strong>Обработка данных и конфиденциальность</strong></span></p>
			<p><span>7.1. </span><span><strong>Сбор и использование персональных
						данных</strong></span><span><br />Компания осуществляет сбор, обработку и хранение персональных данных
					Пользователя в соответствии с действующим законодательством и Политикой конфиденциальности, которая
					является неотъемлемой частью настоящего Соглашения. Персональные данные, собираемые Компанией, могут
					включать, но не ограничиваются: имя, адрес электронной почты, данные о местоположении, платежную
					информацию, а также данные о взаимодействии Пользователя с Сервисом.</span></p>
			<p><span>7.2. </span><span><strong>Цели обработки персональных данных</strong></span><span><br />Собранные
					данные используются для следующих целей:</span></p>
			<ul>
				<li>
					<p><span>Обеспечение функционирования Сервиса и предоставление услуг Пользователю.</span></p>
				</li>
				<li>
					<p><span>Улучшение работы Сервиса и предоставляемых услуг.</span></p>
				</li>
				<li>
					<p><span>Персонализация контента и предложений для Пользователя.</span></p>
				</li>
				<li>
					<p><span>Выполнение обязательств перед Пользователем, включая обработку платежей и предоставление
							вознаграждений.</span></p>
				</li>
				<li>
					<p><span>Поддержка связи с Пользователем, включая отправку уведомлений, маркетинговых материалов и
							других сообщений.</span></p>
				</li>
				<li>
					<p><span>Соблюдение правовых и регуляторных требований.</span></p>
				</li>
			</ul>
			<p><span>7.3. </span><span><strong>Политика конфиденциальности</strong></span><span><br />Пользователь
					соглашается с условиями Политики конфиденциальности, которая регулирует вопросы сбора, использования,
					обработки и хранения персональных данных. Компания обязуется предпринимать все необходимые меры для
					защиты персональных данных Пользователя от несанкционированного доступа, изменения, раскрытия или
					уничтожения.</span></p>
			<p><span>7.4. </span><span><strong>Передача данных третьим лицам</strong></span><span><br />Компания может
					передавать персональные данные Пользователя третьим лицам только в следующих случаях:</span></p>
			<ul>
				<li>
					<p><span>Если это необходимо для предоставления услуг, связанных с использованием Сервиса (например,
							обработка платежей, предоставление вознаграждений от партнеров).</span></p>
				</li>
				<li>
					<p><span>По требованию государственных органов, если это предусмотрено законодательством.</span></p>
				</li>
				<li>
					<p><span>Для защиты прав и законных интересов Компании или третьих лиц, если Пользователь нарушает
							условия настоящего Соглашения.</span></p>
				</li>
			</ul>
			<p><span>7.5. </span><span><strong>Использование данных о местоположении</strong></span><span><br />Сервис
					может собирать и использовать данные о местоположении Пользователя для предоставления контента,
					основанного на географическом положении, таких как рекомендации по маршрутам, квестам и предложениям от
					партнеров. Пользователь может управлять разрешениями на доступ к данным о местоположении через настройки
					своего устройства или приложения.</span></p>
			<p><span>7.6. </span><span><strong>Согласие на обработку данных</strong></span><span><br />Используя Сервис,
					Пользователь дает явное согласие на сбор, обработку и хранение своих персональных данных в соответствии
					с условиями настоящего Соглашения и Политики конфиденциальности. Пользователь имеет право отозвать свое
					согласие на обработку данных в любое время, обратившись в службу поддержки Компании. Однако, отзыв
					согласия может повлечь за собой ограничение функциональности или невозможность использования
					Сервиса.</span></p>
			<p><span>7.7. </span><span><strong>Права Пользователя в отношении персональных
						данных</strong></span><span><br />Пользователь имеет право:</span></p>
			<ul>
				<li>
					<p><span>Запрашивать информацию о том, какие персональные данные о нем хранятся Компанией.</span></p>
				</li>
				<li>
					<p><span>Требовать исправления неточных данных или их удаления, если это не противоречит законодательным
							требованиям.</span></p>
				</li>
				<li>
					<p><span>Ограничивать обработку своих данных в случаях, предусмотренных законодательством.</span></p>
				</li>
				<li>
					<p><span>Переносить свои данные в другой сервис, если это технически возможно и не нарушает права других
							пользователей.</span></p>
				</li>
			</ul>
			<p><span>7.8. </span><span><strong>Хранение и безопасность данных</strong></span><span><br />Компания
					обязуется хранить персональные данные Пользователя только на защищенных серверах и предпринимать
					разумные меры для обеспечения их безопасности. Данные хранятся в течение периода, необходимого для
					достижения целей, указанных в настоящем Соглашении, или в течение периода, установленного
					законодательством.</span></p>
			<p><span>7.9. </span><span><strong>Изменения в Политике
						конфиденциальности</strong></span><span><br />Компания оставляет за собой право вносить изменения в
					Политику конфиденциальности. В случае внесения существенных изменений Пользователь будет уведомлен о них
					через Сервис или по электронной почте. Продолжение использования Сервиса после вступления изменений в
					силу означает согласие Пользователя с обновленной Политикой конфиденциальности.</span></p>
			<p><span><strong>Ответственность и ограничение ответственности</strong></span></p>
			<p><span>8.1. </span><span><strong>Отказ от ответственности за ущерб</strong></span><span><br />Компания не
					несет ответственности за любой ущерб, прямой или косвенный, материальный или нематериальный, который
					может быть причинен Пользователю в результате использования Сервиса, включая, но не ограничиваясь,
					потерей данных, прибыли, престижа, деловой репутации или иного ущерба, связанного с использованием
					Сервиса, его функций и контента.</span></p>
			<p><span>8.2. </span><span><strong>Ограничение ответственности за использование
						Сервиса</strong></span><span><br />Сервис предоставляется на условиях "как есть"
					("</span>asis<span>"), и Компания не дает никаких гарантий, явных или подразумеваемых, относительно его
					работы, доступности, безопасности, качества или пригодности для конкретных целей. Пользователь принимает
					на себя весь риск, связанный с использованием Сервиса.</span></p>
			<p><span>8.3. </span><span><strong>Ответственность за несчастные случаи, произошедшие при использовании
						Сервиса</strong></span><span><br />Пользователь признает и соглашается с тем, что использование
					Сервиса, включая выполнение квестов, участие в маршрутах и использование элементов дополненной
					реальности, может включать физическую активность и передвижение в реальной среде, что связано с
					определенными рисками. Компания не несет ответственности за любые несчастные случаи, травмы, ущерб
					имуществу или иной ущерб, который может быть причинен Пользователю или третьим лицам в результате
					использования Сервиса, включая, но не ограничиваясь, ситуациями, когда Пользователь:</span></p>
			<ul>
				<li>
					<p><span>Взаимодействует с реальными объектами и окружающей средой, используя Сервис.</span></p>
				</li>
				<li>
					<p><span>Не соблюдает меры предосторожности и безопасность при выполнении заданий и квестов.</span></p>
				</li>
				<li>
					<p><span>Нарушает законы и правила дорожного движения, общественного порядка или иные правовые
							нормы.</span></p>
				</li>
			</ul>
			<p><span>Пользователь обязан проявлять осторожность, внимательно следить за своим окружением и действовать в
					соответствии с законами и правилами, действующими в месте его нахождения.</span></p>
			<p><span>8.4. </span><span><strong>Отказ от ответственности за контент третьих
						лиц</strong></span><span><br />Компания не несет ответственности за точность, законность или
					уместность контента, предоставляемого третьими лицами, включая контент, создаваемый другими
					пользователями Сервиса, или контент, размещаемый партнерами Компании. Пользователь использует такой
					контент на свой страх и риск.</span></p>
			<p><span>8.5. </span><span><strong>Ответственность за нарушения и
						правонарушения</strong></span><span><br />Пользователь соглашается возмещать убытки, защищать и
					ограждать Компанию, её аффилированных лиц, сотрудников, агентов и партнеров от любых претензий, убытков,
					обязательств, затрат и расходов (включая разумные гонорары адвокатов), возникающих в результате:</span>
			</p>
			<ul>
				<li>
					<p><span>Нарушения Пользователем условий настоящего Соглашения.</span></p>
				</li>
				<li>
					<p><span>Нарушения прав третьих лиц, включая права на интеллектуальную собственность, неприкосновенность
							частной жизни и другие права.</span></p>
				</li>
				<li>
					<p><span>Любых действий или бездействий Пользователя, повлекших за собой ущерб Компании или третьим
							лицам.</span></p>
				</li>
			</ul>
			<p><span>8.6. </span><span><strong>Ограничение суммы ответственности</strong></span><span><br />В случае,
					если судом будет установлено, что Компания несет ответственность перед Пользователем за ущерб,
					возникающий в связи с использованием Сервиса, такая ответственность ограничивается суммой, не
					превышающей 100 долларов США. Это ограничение применяется ко всем видам убытков, включая прямые,
					косвенные, штрафные, случайные и специальные убытки, даже если Компания была уведомлена о возможности
					таких убытков.</span></p>
			<p><span>8.7. </span><span><strong>Сохранение положений об ограничении
						ответственности</strong></span><span><br />Все положения настоящего раздела об ограничении
					ответственности остаются в силе после прекращения использования Сервиса или расторжения настоящего
					Соглашения, независимо от причин прекращения или расторжения.</span></p>
			<p><span><strong>9. Интеллектуальная собственность</strong></span></p>
			<p><span>9.1. </span><span><strong>Авторские права и товарные знаки</strong></span><span><br />Все
					материалы, размещенные в Сервисе, включая, но не ограничиваясь, текст, графику, логотипы, изображения,
					аудио и видеофайлы, программное обеспечение, а также любые другие элементы, являющиеся частью Сервиса,
					являются собственностью Компании или лицензиаров Компании и защищены авторским правом, патентами,
					товарными знаками и другими законами об интеллектуальной собственности. Использование таких материалов
					без предварительного письменного согласия Компании строго запрещено.</span></p>
			<p><span>9.2. </span><span><strong>Права на материалы, размещенные в
						Сервисе</strong></span><span><br />Пользователь соглашается с тем, что все права на материалы,
					размещенные в Сервисе, включая, но не ограничиваясь, маршруты, квесты, описания, визуальные и звуковые
					элементы, принадлежат Компании или предоставлены ей по лицензии. Пользователю предоставляется
					ограниченная, неисключительная, непередаваемая лицензия на доступ и использование Сервиса и его
					материалов исключительно для личного некоммерческого использования в соответствии с условиями настоящего
					Соглашения.</span></p>
			<p><span>9.3. </span><span><strong>Запрет на несанкционированное
						использование</strong></span><span><br />Пользователю запрещается:</span></p>
			<ul>
				<li>
					<p><span>Использовать любые элементы Сервиса в коммерческих целях без предварительного письменного
							разрешения Компании.</span></p>
				</li>
				<li>
					<p><span>Изменять, адаптировать, переводить, декомпилировать, разбирать или иным образом пытаться
							извлечь исходный код Сервиса или его компонентов.</span></p>
				</li>
				<li>
					<p><span>Создавать производные работы на основе элементов Сервиса без разрешения Компании.</span></p>
				</li>
				<li>
					<p><span>Использовать торговые марки, логотипы, доменные имена или другие идентификационные знаки
							Компании без письменного согласия.</span></p>
				</li>
			</ul>
			<p><span>9.4. </span><span><strong>Пользовательский контент и права интеллектуальной
						собственности</strong></span><span><br />Пользователь сохраняет за собой права на контент, который он
					создает и загружает в Сервис, однако предоставляет Компании неисключительное, бессрочное, безвозмездное
					право на использование, распространение, воспроизведение, изменение и адаптацию такого контента для
					целей, связанных с работой Сервиса. Это включает в себя право на использование Пользовательского
					контента в маркетинговых и рекламных материалах Компании.</span></p>
			<p><span>9.5. </span><span><strong>Уведомления о нарушении авторских прав</strong></span><span><br />Если
					Пользователь считает, что его авторские права или другие права интеллектуальной собственности были
					нарушены в Сервисе, он может направить уведомление о нарушении в Компанию с подробным описанием
					нарушенного контента и доказательствами прав на него. Компания обязуется рассмотреть такие уведомления и
					предпринять соответствующие действия, включая удаление или блокировку доступа к спорному контенту, если
					будет установлено нарушение.</span></p>
			<p><span>9.6. </span><span><strong>Права третьих лиц</strong></span><span><br />Все товарные знаки,
					логотипы, изображения, описания и другие материалы, принадлежащие третьим лицам и используемые в
					Сервисе, остаются собственностью соответствующих владельцев. Компания использует такие материалы с
					разрешения владельцев или в соответствии с законодательством об интеллектуальной собственности.</span>
			</p>
			<p><span>9.7. </span><span><strong>Ответственность за нарушение прав интеллектуальной
						собственности</strong></span><span><br />Пользователь несет ответственность за соблюдение прав
					интеллектуальной собственности при использовании Сервиса. Нарушение прав интеллектуальной собственности
					третьих лиц может повлечь за собой юридические последствия для Пользователя, включая гражданско-правовую
					и уголовную ответственность.</span></p>
			<p><span>9.8. </span><span><strong>Прекращение лицензии на использование
						контента</strong></span><span><br />Компания оставляет за собой право прекратить действие лицензии на
					использование любого контента, размещенного в Сервисе, в любое время и без уведомления, если это
					необходимо для защиты прав Компании или третьих лиц, или если такой контент нарушает условия настоящего
					Соглашения.</span></p>
			<p><span>9.9. </span><span><strong>Изменения в правилах использования интеллектуальной
						собственности</strong></span><span><br />Компания оставляет за собой право вносить изменения в правила
					использования интеллектуальной собственности, о которых Пользователь будет уведомлен через Сервис или по
					электронной почте. Продолжение использования Сервиса после внесения изменений означает согласие
					Пользователя с обновленными правилами.</span></p>
			<h3><span><strong>10. Торговля артефактами и виртуальным содержанием</strong></span></h3>
			<p><span>10.1. Условия торговли виртуальным содержанием<br />Сервис предоставляет Пользователям возможность
					приобретать, обменивать и использовать виртуальные артефакты и другие элементы виртуального содержания,
					включая внутриигровую валюту, предметы и бонусы (далее &ndash; "Виртуальное содержание"). Виртуальное
					содержание может использоваться только в рамках Сервиса и не имеет реальной денежной стоимости.</span>
			</p>
			<p><span>10.2. Право собственности на виртуальное содержание<br />Пользователь признает и соглашается с тем,
					что все права на Виртуальное содержание, приобретенное или полученное в рамках Сервиса, остаются за
					Компанией. Пользователь получает ограниченную, неисключительную, непередаваемую и отзывную лицензию на
					использование Виртуального содержания в соответствии с условиями настоящего Соглашения. Пользователь не
					имеет права на передачу, продажу, обмен или иное распоряжение Виртуальным содержанием вне рамок Сервиса
					без письменного согласия Компании.</span></p>
			<p><span>10.3. Запрет на торговлю за реальные деньги<br />Пользователь соглашается не продавать, не
					обменивать и не предлагать Виртуальное содержание за реальные деньги, товары или услуги, не связанные с
					Сервисом. Любая попытка совершить такую сделку является нарушением настоящего Соглашения и может
					привести к приостановке или прекращению доступа Пользователя к Сервису, а также аннулированию
					Виртуального содержания без возврата средств.</span></p>
			<p><span>10.4. Обмен виртуальными артефактами между пользователями<br />Компания может предоставлять
					Пользователям возможность обмениваться Виртуальным содержанием, включая артефакты, между собой в рамках
					Сервиса. Такой обмен возможен только в соответствии с правилами, установленными Компанией. Компания
					оставляет за собой право ограничивать, отменять или изменять условия обмена Виртуальным содержанием в
					любое время без предварительного уведомления.</span></p>
			<p><span>10.5. Право на ограничение доступа к виртуальному содержанию<br />Компания оставляет за собой право
					приостановить или аннулировать доступ Пользователя к Виртуальному содержанию в случае нарушения
					Пользователем настоящего Соглашения, незаконных действий, мошенничества, или если Компания сочтет такие
					меры необходимыми для защиты своих прав и интересов.</span></p>
			<p><span>10.6. Возврат и обмен виртуального содержания<br />Все транзакции, связанные с приобретением или
					обменом Виртуального содержания, являются окончательными и не подлежат возврату или обмену, за
					исключением случаев, предусмотренных законодательством или настоящим Соглашением. Компания не обязана
					возмещать стоимость Виртуального содержания, если оно было аннулировано в результате нарушения
					Пользователем условий Соглашения.</span></p>
			<p><span>10.7. Риск утраты виртуального содержания<br />Пользователь несет все риски, связанные с утратой
					или повреждением Виртуального содержания, включая случаи, вызванные техническими сбоями, взломом учетной
					записи или другими обстоятельствами, которые находятся вне контроля Компании. Компания не несет
					ответственности за утрату Виртуального содержания и не обязана восстанавливать его в таких
					случаях.</span></p>
			<p><span>10.8. Изменения в условиях использования виртуального содержания<br />Компания оставляет за собой
					право в любое время изменять условия использования Виртуального содержания, включая способы его
					приобретения, обмена и использования. Такие изменения вступают в силу с момента их публикации на Сервисе
					или уведомления Пользователя. Продолжение использования Сервиса после вступления изменений в силу
					означает согласие Пользователя с новыми условиями.</span></p>
			<p><span>10.9. Право на прекращение предоставления услуг<br />Компания оставляет за собой право в любое
					время прекратить предоставление услуг, связанных с Виртуальным содержанием, в том числе аннулировать
					Виртуальное содержание или закрыть доступ к нему. В случае прекращения предоставления услуг Компания не
					несет ответственности за возмещение или компенсацию Пользователю за неиспользованное Виртуальное
					содержание, за исключением случаев, предусмотренных законодательством.</span></p>
			<h3><span><strong>11. Изменения в сервисе</strong></span></h3>
			<p><span>11.1. Право на внесение изменений<br />Компания оставляет за собой право в любое время и по своему
					усмотрению вносить изменения, дополнения или улучшения в Сервис, включая, но не ограничиваясь,
					добавление новых функций, удаление существующих, изменение дизайна, интерфейса, структуры или контента
					Сервиса, а также изменение технических требований для доступа и использования Сервиса.</span></p>
			<p><span>11.2. Обновления и исправления<br />Компания может периодически выпускать обновления и исправления
					для Сервиса, которые могут включать в себя устранение ошибок, улучшение безопасности, добавление новых
					функций или модификацию существующих. Пользователь соглашается на автоматическую установку таких
					обновлений и исправлений, необходимых для продолжения использования Сервиса. Пользователь признает и
					соглашается, что отказ от установки обновлений может привести к ограничению или невозможности
					использования Сервиса.</span></p>
			<p><span>11.3. Изменение условий доступа<br />Компания имеет право изменять условия доступа к Сервису,
					включая ограничение или расширение доступных функций, изменение правил использования, а также введение
					дополнительных условий для доступа к определенным разделам или функциям Сервиса. Такие изменения могут
					вступать в силу немедленно или через определенное время, как указано в уведомлении, предоставленном
					Компанией.</span></p>
			<p><span>11.4. Приостановление работы сервиса<br />Компания оставляет за собой право временно или навсегда
					приостановить работу всего Сервиса или его части для проведения технического обслуживания, обновлений,
					устранения неисправностей, а также по другим причинам, связанным с работой Сервиса. Компания обязуется
					при возможности уведомить Пользователей о предстоящих приостановках, но не несет ответственности за
					убытки, вызванные такими приостановками.</span></p>
			<p><span>11.5. Прекращение предоставления сервиса<br />Компания имеет право в любое время прекратить
					предоставление Сервиса или его части, включая аннулирование учетных записей Пользователей, прекращение
					поддержки отдельных функций или услуг. В случае прекращения предоставления Сервиса Компания обязуется
					уведомить Пользователей об этом заранее, если это возможно. Прекращение предоставления Сервиса не влечет
					за собой обязательства Компании по возмещению Пользователю стоимости неиспользованного Виртуального
					содержания или других элементов, приобретенных в рамках Сервиса, за исключением случаев, предусмотренных
					законодательством.</span></p>
			<p><span>11.6. Изменение условий соглашения<br />Компания оставляет за собой право вносить изменения в
					условия настоящего Соглашения, уведомляя Пользователя об изменениях через Сервис или по электронной
					почте. Изменения вступают в силу с момента их публикации или с даты, указанной в уведомлении.
					Продолжение использования Сервиса после вступления изменений в силу означает согласие Пользователя с
					обновленными условиями Соглашения.</span></p>
			<p><span>11.7. Ограничения ответственности при изменениях<br />Компания не несет ответственности за любые
					убытки или ущерб, возникшие в результате внесения изменений, дополнений, приостановки или прекращения
					работы Сервиса, если такие действия были произведены в соответствии с условиями настоящего Соглашения.
					Пользователь соглашается с тем, что его использование Сервиса после внесения изменений подтверждает его
					согласие с новыми условиями.</span></p>
			<p><span>11.8. Сохранение данных и контента при изменениях<br />Компания прилагает усилия для сохранения
					данных и контента Пользователя при внесении изменений в Сервис, однако не может гарантировать их полную
					сохранность. Пользователь обязан самостоятельно сохранять резервные копии важной информации и контента.
					Компания не несет ответственности за утрату данных или контента в результате изменений в Сервисе.</span>
			</p>
			<p><br /><br /></p>
			<p><br /><br /></p>
			<h3><span>12. </span><span><strong>Прекращение действия учетной записи</strong></span></h3>
			<p><span>12.1. Условия прекращения учетной записи<br />Компания оставляет за собой право прекратить действие
					учетной записи Пользователя в любое время и по любой причине, включая, но не ограничиваясь, нарушение
					условий настоящего Соглашения, незаконное или несанкционированное использование Сервиса, а также по
					другим основаниям, которые Компания сочтет достаточными для такого решения.</span></p>
			<p><span>12.2. Добровольное прекращение учетной записи Пользователем<br />Пользователь имеет право в любое
					время прекратить использование Сервиса и удалить свою учетную запись, обратившись в службу поддержки или
					используя соответствующие функции в настройках учетной записи. При удалении учетной записи все связанные
					с ней данные, включая Виртуальное содержание, могут быть безвозвратно утрачены.</span></p>
			<p><span>12.3. Воздействие на доступ к Виртуальному содержанию<br />В случае прекращения действия учетной
					записи, независимо от причины, доступ Пользователя к Сервису и связанному с ним Виртуальному содержанию,
					включая артефакты, внутриигровую валюту и другие элементы, будет аннулирован. Пользователь не имеет
					права требовать возврата средств или компенсации за неиспользованное Виртуальное содержание или другие
					элементы, приобретенные в рамках Сервиса.</span></p>
			<p><span>12.4. Удаление данных Пользователя<br />При прекращении действия учетной записи все данные
					Пользователя, включая личные данные, контент, созданный Пользователем, и историю активности, могут быть
					удалены или анонимизированы в соответствии с Политикой конфиденциальности Компании. Компания оставляет
					за собой право сохранять определенные данные в течение установленного законом периода или для защиты
					своих законных интересов.</span></p>
			<p><span>12.5. Возможность восстановления учетной записи<br />Компания может предоставить Пользователю
					возможность восстановления учетной записи в течение определенного периода после ее удаления или
					деактивации, если это предусмотрено правилами Сервиса. Восстановление учетной записи возможно только при
					условии, что данные и контент учетной записи не были окончательно удалены или уничтожены. Компания
					оставляет за собой право отказать в восстановлении учетной записи по своему усмотрению.</span></p>
			<p><span>12.6. Ограничение на повторную регистрацию<br />После прекращения действия учетной записи по
					инициативе Компании, Пользователю может быть запрещено повторно регистрироваться в Сервисе или
					использовать другие учетные записи. Нарушение данного ограничения может привести к немедленному
					прекращению действия всех связанных учетных записей.</span></p>
			<p><span>12.7. Возврат средств и компенсации<br />В случае прекращения действия учетной записи Пользователя
					по любой причине, Пользователь не имеет права на возврат средств, компенсацию или иные формы возмещения
					за неиспользованные услуги, Виртуальное содержание или другие элементы, приобретенные в рамках Сервиса,
					за исключением случаев, прямо предусмотренных законодательством.</span></p>
			<p><span>12.8. Закрытие учетной записи при нарушении законодательства<br />Если учетная запись Пользователя
					была закрыта в связи с нарушением законодательства, Компания оставляет за собой право сообщить о данном
					нарушении соответствующим правоохранительным или регулирующим органам и предоставить им все необходимые
					данные и доказательства.</span></p>
			<p><span>12.9. Сохранение прав и обязанностей<br />Прекращение действия учетной записи Пользователя не
					освобождает его от выполнения обязательств, возникших до момента прекращения, включая обязательства по
					возмещению убытков, вызванных нарушением условий настоящего Соглашения. Положения настоящего Соглашения,
					касающиеся ответственности, возмещения убытков, конфиденциальности и интеллектуальной собственности,
					остаются в силе после прекращения действия учетной записи.</span></p>
			<h3><span>13. </span><span><strong>Конфликты и разбирательства</strong></span></h3>
			<p><span>13.1. Урегулирование споров<br />В случае возникновения спора между Пользователем и Компанией,
					стороны обязуются предпринять все возможные усилия для урегулирования конфликта мирным путем, путем
					переговоров и обмена информацией. Если стороны не могут достичь соглашения, спор подлежит разрешению в
					порядке, установленном настоящим Соглашением.</span></p>
			<p><span>13.2. Обязательный арбитраж<br />Если мирное урегулирование не достигнуто, все споры, претензии или
					разногласия, возникающие в связи с использованием Сервиса или настоящим Соглашением, подлежат
					окончательному и обязательному арбитражу в соответствии с правилами арбитражного суда, выбранного
					Компанией. Арбитраж проводится одним арбитром, назначенным в соответствии с этими правилами, и его
					решение является окончательным и обязательным для обеих сторон.</span></p>
			<p><span>13.3. Отказ от коллективных исков<br />Пользователь соглашается на рассмотрение всех споров с
					Компанией исключительно на индивидуальной основе и отказывается от права на участие в любом коллективном
					иске или судебном процессе, направленном против Компании. Это означает, что Пользователь не может
					предлагать или участвовать в коллективных исках, направленных против Компании, в рамках одного судебного
					дела с другими Пользователями.</span></p>
			<p><span>13.4. Применимое право<br />Настоящее Соглашение, а также все споры и претензии, связанные с его
					исполнением, толкуются и регулируются в соответствии с законодательством ПМР, без учета положений о
					конфликте законов. Пользователь соглашается с тем, что законодательство Республики ПМР будет применяться
					ко всем аспектам использования Сервиса и правовым отношениям между Пользователем и Компанией.</span></p>
			<p><span>13.5. Юрисдикция<br />Любые судебные разбирательства, вытекающие из настоящего Соглашения или
					связанные с ним, подлежат исключительной юрисдикции судов ПМР. Пользователь соглашается с тем, что любые
					иски, предъявляемые против Компании, будут рассматриваться в судах, расположенных в ПМР.</span></p>
			<p><span>13.6. Подача претензий<br />Все претензии, связанные с использованием Сервиса или нарушением
					условий настоящего Соглашения, должны быть поданы Пользователем в течение одного (1) года с момента
					возникновения причин для подачи претензии. По истечении этого срока претензии считаются
					недействительными и не подлежат рассмотрению.</span></p>
			<p><span>13.7. Изменения в условиях арбитража и разбирательств<br />Компания оставляет за собой право
					вносить изменения в положения о разрешении споров и арбитраже, о которых Пользователь будет уведомлен
					через Сервис или по электронной почте. Продолжение использования Сервиса после вступления изменений в
					силу означает согласие Пользователя с обновленными условиями арбитража и разрешения споров.</span></p>
			<p><span>13.8. Согласие на получение юридических уведомлений<br />Пользователь соглашается получать все
					юридические уведомления и сообщения от Компании в электронном виде, через электронную почту или через
					публикацию уведомлений на Сервисе. Пользователь также соглашается с тем, что такие электронные
					уведомления имеют ту же юридическую силу, что и уведомления, направленные в письменной форме.</span></p>
			<p><span>13.9. Возмещение убытков и защита интересов<br />Пользователь обязуется возмещать Компании любые
					убытки, издержки, включая разумные гонорары адвокатов, понесенные в результате нарушений Пользователем
					условий настоящего Соглашения, а также в случае подачи против Компании претензий или исков, связанных с
					действиями или бездействием Пользователя.</span></p>
			<h3><span><strong>14. Заключительные положения</strong></span></h3>
			<p><span>14.1. Полнота соглашения<br />Настоящее Соглашение, включая Политику конфиденциальности и любые
					другие документы, прямо включенные в настоящее Соглашение ссылкой, составляет полное соглашение между
					Пользователем и Компанией относительно использования Сервиса. Все предыдущие договоренности, соглашения
					или заявления, сделанные в устной или письменной форме, аннулируются и заменяются условиями настоящего
					Соглашения.</span></p>
			<p><span>14.2. Недействительность отдельных положений<br />Если какое-либо из положений настоящего
					Соглашения будет признано недействительным или не имеющим юридической силы компетентным судом или
					арбитражным органом, такое положение будет исполнено в максимально возможном объеме, допускаемом
					применимым законодательством. Недействительность такого положения не повлияет на законность и силу
					остальных положений Соглашения, которые остаются в полной силе.</span></p>
			<p><span>14.3. Отказ от прав<br />Отказ Компании от любого права или положения настоящего Соглашения не
					считается отказом от такого права или положения в будущем. Любой отказ от прав должен быть оформлен в
					письменной форме и подписан уполномоченным представителем Компании.</span></p>
			<p><span>14.4. Передача прав и обязанностей<br />Пользователь не имеет права передавать свои права и
					обязанности по настоящему Соглашению без предварительного письменного согласия Компании. Компания вправе
					передавать свои права и обязанности по настоящему Соглашению без согласия Пользователя в случае продажи,
					слияния, реорганизации или иной передачи активов.</span></p>
			<p><span>14.5. Изменение условий соглашения<br />Компания оставляет за собой право вносить изменения в
					настоящее Соглашение в любое время. Изменения вступают в силу с момента их публикации на Сервисе или с
					даты, указанной в уведомлении. Пользователь обязан регулярно просматривать условия Соглашения.
					Продолжение использования Сервиса после внесения изменений означает согласие Пользователя с обновленными
					условиями.</span></p>
			<p><span>14.6. Применимое право<br />Настоящее Соглашение регулируется и толкуется в соответствии с
					законодательством ПМР, за исключением коллизионных норм. Пользователь соглашается с тем, что все
					вопросы, вытекающие из настоящего Соглашения или связанные с ним, будут регулироваться законодательством
					ПМР.</span></p>
			<p><span>14.7. Языковая версия соглашения<br />Настоящее Соглашение может быть переведено на другие языки
					для удобства Пользователей. В случае расхождений между рускоязычной версией и переводами на другие
					языки, преимущество имеет рускоязычная версия Соглашения.</span></p>
			<p><span>14.8. Форс-мажор<br />Компания не несет ответственности за неисполнение или задержку в исполнении
					своих обязательств по настоящему Соглашению, если это вызвано обстоятельствами, находящимися вне
					разумного контроля Компании, включая, но не ограничиваясь, стихийными бедствиями, военными действиями,
					террористическими актами, забастовками, сбоями в работе коммуникационных систем или инфраструктуры,
					юридическими действиями властей или другими форс-мажорными обстоятельствами.</span></p>
			<p><span>14.9. Согласие на электронные уведомления<br />Пользователь соглашается получать все уведомления,
					соглашения, раскрытия информации и другие сообщения в электронном виде, если это не противоречит
					применимому законодательству. Пользователь признает, что такие электронные сообщения имеют ту же
					юридическую силу, что и уведомления, направленные в письменной форме.</span></p>
			<p><span>14.10. Срок действия положений после прекращения соглашения<br />Все положения, которые по своей
					сути должны оставаться в силе после прекращения настоящего Соглашения, включая, но не ограничиваясь,
					положения об ограничении ответственности, защите интеллектуальной собственности, конфиденциальности,
					урегулировании споров и возмещении убытков, продолжают действовать и после прекращения
					Соглашения.</span></p>
			<h3><span>15. </span><span><strong>Специальные условия для пользователей из Российской
						Федерации</strong></span></h3>
			<p><span>15.1. Хранение и обработка персональных данных<br />В соответствии с Федеральным законом Российской
					Федерации №242-ФЗ от 21 июля 2014 года и №152-ФЗ "О персональных данных", все персональные данные
					граждан Российской Федерации, собираемые и обрабатываемые сервисом "Артифактум", хранятся исключительно
					на серверах, расположенных на территории Российской Федерации. Данные не передаются за пределы России и
					обрабатываются в строгом соответствии с требованиями российского законодательства.</span></p>
			<p><span>15.2. Согласие на обработку данных<br />При регистрации и использовании сервиса, пользователи из
					Российской Федерации дают свое согласие на сбор, хранение и обработку своих персональных данных в
					соответствии с настоящими правилами. Пользователи уведомлены и соглашаются с тем, что их данные не будут
					передаваться за границу и будут обрабатываться исключительно на территории РФ.</span></p>
			<p><span>15.3. Защита персональных данных<br />Сервис "Артифактум" обязуется принимать все необходимые меры
					для защиты персональных данных пользователей от несанкционированного доступа, утраты, изменения или
					уничтожения. Меры безопасности соответствуют требованиям ФСТЭК и ФСБ России, включая использование
					сертифицированных средств защиты информации.</span></p>
			<p><span>15.4. Права пользователей<br />Пользователи имеют право на доступ к своим персональным данным, их
					изменение, блокирование или удаление в соответствии с требованиями Федерального закона №152-ФЗ.
					Пользователи могут в любой момент отозвать свое согласие на обработку данных или подать жалобу в случае
					нарушения их прав.</span></p>
			<p><span>15.5. Уничтожение данных<br />Персональные данные пользователей будут уничтожены после достижения
					целей их обработки, либо по запросу пользователя, в соответствии с установленными сроками и требованиями
					Федерального закона №152-ФЗ.</span></p>
			<p><span>15.6. Регистрация в реестре операторов персональных данных<br />Сервис "Артифактум" зарегистрирован
					в реестре операторов персональных данных, что подтверждает его соответствие требованиям законодательства
					Российской Федерации в области обработки и хранения персональных данных.</span></p>
			<p><span>15.7. Обновления и изменения правил<br />Сервис "Артифактум" оставляет за собой право вносить
					изменения в данный блок правил в соответствии с изменениями в законодательстве или внутренними
					потребностями сервиса. Пользователи будут уведомлены о таких изменениях, и продолжение использования
					сервиса будет рассматриваться как согласие с обновленными правилами.</span></p>
		</div>
	</div>

</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const lang = ref(navigator.language === "ru" ? "ru" : "en");
const isSelect = ref(false);
const isTerms = ref(false);

onMounted(() => {
	isSelect.value = false;
	lang.value = route?.query?.lang?.toString() || localStorage.getItem("lang") || "ru";
});
const showMenu = () => {
	isSelect.value = !isSelect.value;
};
</script>

<style lang="less">
.terms-conditions {}
</style>
