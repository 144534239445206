<!-- @format -->

<template>
	<layout>
		<fullpage>
			<header-static
				:visibleBtnCabinet="false"
				@showMenu="menuIsActive = true"
				@showCabinet="cabinetIsActive = true"
			/>
			<section-main />
			<section-about />
			<section-advantages />
			<section-share />
			<section-bun />
			<section-forms />
			<section-map />

			<footer-default />
		</fullpage>

		<overlay
			:class="{ show: menuIsActive || cabinetIsActive }"
			@click="menuIsActive = false"
		/>
		<burger-menu :class="{ active: menuIsActive }" @closeMenu="menuIsActive = false" />
		<section-enter
			:class="{ active: cabinetIsActive }"
			@close="cabinetIsActive = false"
		/>

		<!-- <section-forget-password /> -->

		<!-- <popup>
            <popup-img><img :src="notificationSrc" alt="Ура"></popup-img>
            <popup-text>{{notificationText}}</popup-text>
        </popup> -->

		<!-- <popup-change-password
			:visible="showPopupChangePassword"
			@close="closePopupChangePassword"
			@change="changePassword"
		/> -->

		<!-- <popup-change-email /> -->
	</layout>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
const lang = ref("ru");
const menuIsActive = ref(false);
const cabinetIsActive = ref(false);

const notificationSrc = () => {
	return "@assets/img/check.svg";
};
</script>

<style lang="less" scoped>
.ellipse-yellow {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.ellipse-yellow img {
	max-width: 100%;
	height: auto;
}

@media all and (max-width: 1000px) {
	.ellipse-yellow {
		top: inherit;
		bottom: 50px;
	}
}
</style>
