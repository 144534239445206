<!-- @format -->

<template>
	<popup class="popup-video">
		<iframe
			width="1214"
			height="700"
			max-width="1214"
			src="https://www.youtube.com/embed/qNZIJmEZ91g"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
		></iframe>
	</popup>
</template>

<script lang="ts" setup>
import Vue, { ref } from "vue";

const isClickedContinue = ref(false);
const email = ref("");
</script>

<style lang="css" scoped>
.popup-video iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
</style>
