import { createApp } from "vue";
import { App } from "./components";
import router from "./router";
import i18n from "./lang/locale";

// import "./register-router-hooks";
// import "./register-components";

// Импортируем и используем плагины
import api from "./api/dataSource/api/apiDataSource.plugin";
import moment from "moment";
// import VueMoment from "vue-moment";

// Определяем URL API
const apiUrl =
  location.host.indexOf("dev.") > -1 || location.host.indexOf("http://localhost/") > -1
    ? "dev-landing.artifactum.io"
    : "landing.artifactum.io";

const websocketUrl = `${location.protocol === "http:" ? "ws" : "wss"}://${apiUrl}/websocket`;

// Создаем приложение
const app = createApp(App);

// Используем плагины
app.use(api as any, websocketUrl);

// moment.locale("ru");
// app.use(VueMoment as any, {
//   moment,
// });



const requireComponent = require.context(
  './components', // Путь к директории с компонентами
  true,           // Включить поддиректории
  /[A-Za-z]\w+\.(vue|js)$/ // Регулярное выражение для выбора файлов компонентов
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
    app.component(componentName, componentConfig.default || componentConfig);
});



app.use(router);
app.use(i18n as any);

// Монтируем приложение
app.mount("#app");
