<!-- @format -->

<template>
	<div>
		<slot name="header">
			<layoutHeader @changeLang="onChangeLang" />
		</slot>

		<div id="content">
			<div class="wrapper">
				<slot />
			</div>
		</div>
		<slot name="footer">
			<layoutFooter />
		</slot>
	</div>
</template>

<script lang="ts" setup>
import { LayoutHeader, LayoutFooter } from "@/components";
import { setLocale } from "@/lang/locale";
import Vue from "vue";

function onChangeLang(lang: string) {
	setLocale(lang);
}
</script>
