<!-- @format -->

<template>
	<section class="feedback">
		<div class="container-fluid">
			<div action="" class="feedback-form row">
				<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-col">
					<div class="form-flex-block">

						
						<pos-input-label
							:label="$t('feedback.Enter name')"
							v-model:value="name1"
							htmlClass=""
							name="name"
							type="name"
							:placeholder="$t('feedback.Name')"
							:required="true"
						>
							<svg width="12" height="12">
								<use xlink:href="@assets/img/svg-sprite.svg#name-user-icon"></use>
							</svg>
						</pos-input-label>

						<pos-input-label
							ref="email5Ref"
							:label="$t('feedback.Enter e-mail')"
							v-model:value="email5"
							htmlClass=""
							name="email5"
							type="email"
							placeholder="example@gmail.com"
							:required="true"
							:regex="email5Regex"
						>
							<svg width="12" height="12">
								<use xlink:href="@assets/img/svg-sprite.svg#email-input-icon"></use>
							</svg>
						</pos-input-label>
					</div>
				</div>

				<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-col">
					<div class="form-flex-block">
						<pos-textarea-label
							ref="messageRef"
							:label="$t('feedback.Enter your message')"
							v-model:value="message"
							htmlClass=""
							name="message"
							type="text"
							:placeholder="$t('feedback.Enter your message')"
							:required="true"
							:regex="messageRegex"
						>
						</pos-textarea-label>
					</div>
				</div>
				<div class="col-12 btn-col">
					<form-button @on-click="next()">{{ $t("feedback.Send") }}</form-button>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts" setup>
import Vue, { ref } from "vue";

const email5Regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameRegex: RegExp = /[\w\s А-я_\s\-\.][^\d_\\\/\*\+\,\_\~\`\!\@\"\#\№\$\;\:\%\^\&\?\()]+$/; //'[\\w\s]+';
const messageRegex: RegExp = /[\w\s\d]+$/;

const name: string = "";
const name1: string = "";
const email5: string = "";
const message: string = "";
const nameRef = ref();
const email5Ref = ref();
const messageRef = ref();

let isClickedContinue: boolean = false;

function next() {
	// isClickedContinue = true;
	// //если проходит валидация
	// if (
	// 	!this.GetRef("email5").checkValidation() &&
	// 	!this.GetRef("name").checkValidation() &&
	// 	!this.GetRef("message").checkValidation()
	// ) {
	// 	return;
	// } else if (
	// 	!this.GetRef("email5").checkValidation() ||
	// 	!this.GetRef("name").checkValidation() ||
	// 	!this.GetRef("message").checkValidation()
	// ) {
	// 	return;
	// }
}
</script>

<style lang="css" scoped>
.feedback {
	padding: 55px 0;
}

.feedback-form {
	margin-right: -30px;
	margin-left: -30px;
}

.btn-col {
	display: flex;
	justify-content: center;
}

.form-col {
	padding-left: 30px;
	padding-right: 30px;
}

@media all and (max-width: 900px) {
	.feedback-form {
		margin-right: -15px;
		margin-left: -15px;
	}
	.form-col {
		padding-left: 15px;
		padding-right: 15px;
	}
}
</style>
