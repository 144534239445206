<!-- @format -->

<template>
	<div class="socials-share">
		<div class="group-button">
			<googleStore-button />

			<appStore-button />
			<ruStore-button />
		</div>

		<div class="qr-code">
			<img id="imageqr" v-if="theme == 'dark'" class="mr-1" width="200" height="200" src="@assets/img/qr-dark.png" />
			<img id="imageqr" v-else-if="theme == 'light'" class="mr-1" width="200" height="200" src="@assets/img/qr.png" />
			<!-- <img width="200" height="200" src="@assets/img/app/qr-ios.png" /> -->
		</div>
	</div>
</template>

<script lang="ts" setup>
import Vue, { onBeforeUnmount, onMounted, ref } from "vue";
import { GoogleStoreButton, AppStoreButton } from "@/components";

import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";


const getMobileOperatingSystem = (): string => {
	const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera;

	// Проверяем устройства на базе Android
	if (/android/i.test(userAgent)) {
		return "Android";
	}
	if (/Windows/.test(userAgent)) {
		return "Windows";
	}

	// Проверяем устройства на базе iOS
	if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
		return "iOS";
	}

	return "unknown";
};
const route = useRoute()


const theme = ref(localStorage.getItem("theme") || "light");

watch(() => route.fullPath, (value) => {
	if (value.includes('#main-section'))
		blinkBorder()
})
const updateLocale = () => {
	theme.value = document.documentElement.getAttribute('data-theme') || 'light';
};
onMounted(() => {
	const os = getMobileOperatingSystem();

	const observer = new MutationObserver(updateLocale);
	observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-theme'] });
	// blinkBorder()

	// Сохраняем observer в переменную, чтобы можно было отключить его при размонтировании компонента
	onBeforeUnmount(() => {
		observer.disconnect();
	});
});
function blinkBorder() {
	const image = document.getElementById("imageqr");
	image.classList.remove("blink-border"); // Убираем класс, чтобы сбросить анимацию
	void image.offsetWidth; // Триггерим перерисовку
	image.classList.add("blink-border"); // Добавляем класс снова
}
</script>

<style lang="css" scoped>
.qr-code {
	gap: 5px;


}

.socials-share {
	display: flex;
	column-gap: 24px;
	margin-top: 58px;
}

.blink-border {
	animation: blink 1s ease-in-out 3;
}

@keyframes blink {

	0%,
	100% {
		border: 5px solid transparent;
	}

	50% {
		border: 20px solid #F16063 ;
		/* Цвет и толщина рамки во время мигания */
	}
}

.group-button {
	display: flex;
	flex-direction: column;
	display: none;
	row-gap: 16px;
	/* align-items: center; */
}

@media all and (max-width: 1300px) {
	.group-button {
		display: none;
		flex-direction: column;
		align-items: flex-start;
	}

	.btn--animate:first-child {
		margin-bottom: 10px;
	}
}

@media all and (max-width: 1000px) {
	.group-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.btn--animate:first-child {
		margin-bottom: 0;
	}

	.socials-share {
		justify-content: center;
	}

	.group-button {
		width: 100%;
	}

	.qr-code {
		display: none;
	}


}

@media all and (max-width: 570px) {
	.group-button {

		flex-direction: column;
		align-items: center;
	}

	.btn--animate:first-child {
		margin-bottom: 10px;
	}

}

.qr-code {
	/* display: none; */
	justify-content: center;
	/* padding-top: 40px; */
}

.qr-code>img {
	width: 172px;
	height: 172px;
}

@media (min-width: 992px) {
	.qr-code {
		display: flex;
	}
}

@media (min-width: 1000px) and (max-width: 1300px) {
	.qr-code {
		max-width: 271px;
	}
}

@media (min-width: 1301px) {
	.qr-code {
		justify-content: start;
	}
}
</style>
