<!-- @format -->

<template>
	<div class="select-box select-box-main">
		<div class="options-container" :class="{ active: optionsContainerIsActive }">
			<div class="option" @click="lang('ru')">
				<input type="radio" class="radio" id="grade-a" name="category" />
				<label for="grade-a">
					<div class="flag"><img src="@assets/img/ru-flag.svg" alt="" /></div>
				</label>
			</div>
			<div class="option" @click="lang('en')">
				<input type="radio" class="radio" id="grade-b" name="category" />
				<label for="grade-b">
					<div class="flag"><img src="@assets/img/en-flag.png" alt="" /></div>
				</label>
			</div>

			<div class="option" @click="lang('lv')">
				<input type="radio" class="radio" id="grade-c" name="category" />
				<label for="grade-c">
					<div class="flag"><img src="@assets/img/lv-flag.png" alt="" /></div>
				</label>
			</div>
		</div>
		<div class="selected" @click="onSelectedClick">
			<div class="flag" v-if="currentLang == 'ru'">
				<img src="@assets/img/ru-flag.svg" alt="" />
			</div>
			<div class="flag" v-if="currentLang == 'lv'">
				<img src="@assets/img/lv-flag.svg" alt="" />
			</div>
			<div class="flag" v-if="currentLang == 'en'">
				<img src="@assets/img/en-flag.svg" alt="" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { setLocale } from "@/lang/locale";
import { computed, onMounted, ref, watch } from "vue";
const emit = defineEmits({ changeLang: String });
let optionsContainerIsActive = ref(false);

const currentLang = ref(localStorage.getItem("lang") || "ru");

onMounted(() => {
	setLocale(currentLang.value);
});

const onSelectedClick = () => {
	optionsContainerIsActive.value = !optionsContainerIsActive.value;
};

const lang = (lang: string) => {
	localStorage.setItem("lang", lang);
	currentLang.value = lang;
	optionsContainerIsActive.value = false;
	setLocale(currentLang.value);
};
</script>

<style lang="less" scoped>
.select-box {
	/* margin-right: 70px; */
	margin-left: 10px;
	display: flex;
	width: 60px;
	height: 24px;
	align-items: center;
	position: relative;
}

.select-box .options-container {
	max-height: 0;
	opacity: 0;
	transition: all 0.4s;
	overflow: hidden;
	order: 1;
	position: absolute;
	z-index: 10;
	top: 30px;
	background: transparent;
}

.flag {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.flag img {
	width: 24px;
	height: 16px;
}

.selected {
	padding-left: 5px;
	position: relative;
	width: 60px;
	height: 16px;
	background-color: transparent;
	order: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.selected::after {
	content: url("@assets/img/lang-arrow.svg");
	/* content: ''; */
	display: inline-block;
	text-rendering: auto;
	position: absolute;
	right: 0;
	top: 50%;
	transition: transform 0.5s;
	transform: translateY(-50%);
	/* background: url("/assets/img/lang-arrow.svg") no-repeat;
    background-size: contain; */
}

.select-box .options-container.active {
	max-height: 240px;
	min-height: 100px;
	opacity: 1;
	overflow-y: auto;
	width: 50px;
}

.select-box .options-container.active + .selected::after {
	transform: translateY(-50%) rotateX(180deg);
}

/* .select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: #0d141f;
  background: #81878f;
  background: #f1f2f3;
  border-radius: 0 5px 5px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: #525861;
  background: #81878f;
  border-radius: 0 5px 5px 0;
} */
.select-box .option,
.selected {
	cursor: pointer;
}

.select-box .option {
	padding-left: 5px;
	height: 26px;
	display: flex;
	align-items: center;
	transition: all 0.2s ease;
}

.select-box .option:hover {
	background: #edeaff;
	/* background: black; */
}

.select-box label {
	cursor: pointer;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.select-box .option .radio {
	display: none;
}

@media all and (max-width: 1350px) {
	.select-box {
		margin-left: 0;
	}
}

@media all and (max-width: 1150px) {
	.select-box {
		display: none;
	}

	.locale--burger {
		margin-bottom: 25px;
		display: flex;
		font-size: 17px;
		font-weight: 500;
	}
}

/* @media all and (min-width: 1920px) {
    .select-box {
        width: calc((100vw + 100000px)/(1920 + 100000) * (60 + 5000) - 5000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (24 + 2000) - 2000px);
    }
    .flag img {
        width: calc((100vw + 100000px)/(1920 + 100000) * (24 + 2000) - 2000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (16 + 1500) - 1500px);
    }

    .select-box .options-container {
        top: calc((100vw + 100000px)/(1920 + 100000) * (30 + 1500) - 1500px);
    }

    .select-box .options-container.active {
        max-height: 240px;
        min-height: calc((100vw + 100000px)/(1920 + 100000) * (60 + 5000) - 5000px);
        opacity: 1;
        overflow-y: auto;
        width: calc((100vw + 100000px)/(1920 + 100000) * (60 + 5000) - 5000px);
    }

    .select-box .option {
        padding-left: calc((100vw + 100000px)/(1920 + 100000) * (5 + 1000) - 1000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (26 + 2000) - 2000px);
        display: flex;
        align-items: center;
        transition: all 0.2s ease;
    }

    .selected {
        padding-left: calc((100vw + 100000px)/(1920 + 100000) * (5 + 1000) - 1000px);
        width: 100%;
    }

    .selected::after {
        width: calc((100vw + 100000px)/(1920 + 100000) * (10 + 1000) - 1000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (16 + 1000) - 1000px);
    }
} */
</style>
