export class CropImageModel {
  constructor(obj?: CropImageModel) {
    if (obj) Object.assign(this, obj);
  }
  Id: number = 0;
  Image?: string;
  CropImage?: string;
  ImageName?: string;
  Height?: number;
  Width?: number;
  Top?: number;
  Left?: number;
}
