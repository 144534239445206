<!-- @format -->

<template>
	<div class="app-page">
		<!-- <button @click="getCurrentPosition">Определить страну</button> -->
		<!-- <p v-if="country">Вы находитесь в: {{ country }}</p> -->
		<div class="pb-5"><img src="@assets/img/app/logo.png"></div>
		<googleStore-button class="m-2" isNoApp/>
		<ruStore-button class="m-2" />
	</div>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";

import * as turf from "@turf/turf";
import coord from "./rus.json";
import { useRoute, useRouter } from "vue-router";


const router = useRouter();
const latitude = ref(null);
const longitude = ref(null);
const isInRussia = ref(false);
const apiKey = "AIzaSyCrgbYG2yojJ_BtgmwhRnwKRppM147TD_8"; // Вставьте свой ключ API Google

const country = ref("");
const countriesGeoJSON = ref(coord);

onMounted(async () => {
	await getLocation();
	await checkApp();
});

async function getLocation() {
	if (navigator.geolocation) {
		await navigator.geolocation.getCurrentPosition(
			await processPosition,
			await showError
		);
	} else {
		console.error("Geolocation is not supported by this browser.");
	}
}
async function processPosition(position: any) {
	latitude.value = position.coords.latitude;
	longitude.value = position.coords.longitude;
	await reverseGeocode(latitude.value, longitude.value);
}

const reverseGeocode = async (lat: number, lon: number) => {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;
	// this.determineCountry(this.latitude, this.longitude);

	await fetch(url)
		.then((response) => response.json())
		.then(async (data) => {
			const result = data.results.find((result: any) => result.types.includes("country"));
			if (result && result.address_components) {
				isInRussia.value = await result.address_components.some((component: any) => {
					country.value = component.long_name;

					const res = component.short_name === "RU" || component.long_name === "Russia";
					if (res) {
					}
					return res;
				});
			}
		})
		.catch((error) => {
			console.error("Error with Google Geocoding API:", error);
		});
};

const showError = (error: any) => {
	switch (error.code) {
		case error.PERMISSION_DENIED:
			console.error("User denied the request for Geolocation.");
			break;
		case error.POSITION_UNAVAILABLE:
			console.error("Location information is unavailable.");
			break;
		case error.TIMEOUT:
			console.error("The request to get user location timed out.");
			break;
		case error.UNKNOWN_ERROR:
			console.error("An unknown error occurred.");
			break;
	}
};

const getCurrentPosition = () => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;
				console.log(`Широта: ${latitude}, Долгота: ${longitude}`);
			},
			(error) => {
				console.error(`Ошибка получения геопозиции: ${error.message}`);
			}
		);
	} else {
		console.error("Геолокация не поддерживается вашим браузером.");
	}
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;
				determineCountry(longitude, latitude);
			},
			(error) => {
				console.error(`Ошибка получения геопозиции: ${error.message}`);
			}
		);
	} else {
		console.error("Геолокация не поддерживается вашим браузером.");
	}
};
const determineCountry = (longitude: number, latitude: number) => {
	const point = turf.point([longitude, latitude]);

	for (let i = 0; i < countriesGeoJSON.value.features.length; i++) {
		const countryLocal = countriesGeoJSON.value.features[i];
		if (turf.booleanPointInPolygon(point, country as any)) {
			country.value = countryLocal.properties.name;
			return;
		}
	}

	country.value = "Страна не найдена";
};

const checkApp = async () => {
	var userAgent = navigator.userAgent.toLowerCase();

	// this.getCurrentPosition();
	if (userAgent.indexOf("android") !== -1) {
		// Пользователь зашел с Android-устройства
		// location.replace(
		// 	"https://play.google.com/store/apps/details?id=com.tirscript.artifactum"
		// );
	} else if (
		userAgent.indexOf("iphone") !== -1 ||
		userAgent.indexOf("ipad") !== -1 ||
		userAgent.indexOf("ipod") !== -1
	) {
		// Пользователь зашел с iOS-устройства
		location.replace("https://apps.apple.com/md/app/artifactum/id1535473486");
	} else {
		// Пользователь зашел с другого устройства
		// router.push({ name: "main" });
	}
};
</script>
<style lang="css" scoped>
.app-page {
	background: #282737;
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
</style>
