<!-- @format -->

<template>
	<layout>
		<fullpage>
			<header-static
				:visibleBtnCabinet="false"
				@showMenu="menuIsActive = true"
				@showCabinet="cabinetIsActive = true"
			/>
			<static-title>{{ $t("menubar.About") }} </static-title>
			<section-video />
			<section-features />
			<footer-default />
		</fullpage>

		<overlay
			:class="{ show: menuIsActive || cabinetIsActive }"
			@click="menuIsActive = false"
		/>
		<burger-menu :class="{ active: menuIsActive }" @closeMenu="menuIsActive = false" />
		<section-enter
			:class="{ active: cabinetIsActive }"
			@close="cabinetIsActive = false"
		/>
		<!-- <section-forget-password /> -->

		<!-- <popup>
            <popup-img><img src="@assets/img/check.svg" alt="Ура"></popup-img>
            <popup-text>Ваш имейл подтвержден, авторизуйтесь в приложении  Artifactum</popup-text>
        </popup> -->

		<!-- <popup-video /> -->
	</layout>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const menuIsActive = ref(false);
const cabinetIsActive = ref(false);

const notificationSrc = () => {
	return "@assets/img/check.svg";
};
</script>

<style lang="css" scoped></style>
