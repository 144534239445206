<!-- @format -->

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

import { useRouter } from "vue-router";

import { MainAdapterService } from "@/components/pages";

import { MapFilterModel, MapPointMarkerModel } from "@/components/controls/ui-map";
import { RouteFilterModel } from "@/components/controls/ui-map/models-ext/RouteFilterModel";
import RouteItemClusterModel from "@/components/controls/ui-map/models-ext/RouteItemClusterModel";

/**
 * * Сервис для работы с маршрутами
 */

const clusters = ref(new Array<RouteItemClusterModel>());
/**
 * * Идет ли загрузка
 */
const isLoading = ref(false);
/**
 * * Фильтр
 */
const filter = ref(new RouteFilterModel());
/**
 * * Список точек
 */
const markers = computed(() => clusters.value.map((x) => new MapPointMarkerModel(x)));

const clasters = ref(Array<RouteItemClusterModel>());
/**
 * * Приведение типов
 */
const getCurrentPoint = (value: any) => {
	return value.point as MapPointMarkerModel;
};

/**
 * * Фильтр для карты
 */
const mapFilter = computed(
	() =>
		new MapFilterModel({
			Zoom: filter.value.Zoom,
			NorthEast: filter.value.NorthEast,
			SouthEast: filter.value.SouthEast,
		})
);

/**
 * * Маршруты
 */
const router = useRouter();

/**
 * * Перейти на изменение маршрута
 * @param route Маршрут
 */
// const onEdit = (route: MapPointMarkerModel) => {
// 	router.push({ name: "route-edit", params: { id: route.Id } });
// };
const adapter = new MainAdapterService();
let getRouteClusters: any;
onMounted(async () => {
	clasters.value = await adapter.getRouteClusters(
		new MapFilterModel({
			Zoom: filter.value.Zoom,
			NorthEast: filter.value.NorthEast,
			SouthEast: filter.value.SouthEast,
		})
	);
});
</script>

<template>
	<div class="route-map-list">
		<ui-map
			:markers="markers"
			:isPolyline="false"
			:filter="mapFilter"
			:is-loader="isLoading"
			height="800px"
			@filter="getRouteClusters"
		>
			<template #marker-popup="{ marker }">
				<!-- <map-point-popup-item
					v-if="getCurrentPoint(marker)?.DetailUrl"
					:data="getCurrentPoint(marker)"
					@edit="onEdit"
				/> -->
				{{ marker }}
			</template></ui-map
		>
	</div>
</template>

<style lang="scss" scoped>
.route-map-list {
	width: 100%;
}
</style>
