<!-- @format -->

<template>
	<div class="privacy-policy">

		<div>
			<h1>{{ $t("footer.Privacy Policy") }}</h1>
			<p>
				<b style="font-size: 24px; font-weight: 900; color: #ffb83a">"Artifactum"</b>
				(«Приложение») уважает конфиденциальность своих пользователей и обязуется
				защищать их личные данные. Настоящая Политика конфиденциальности описывает
				наши действия в отношении сбора, использования, обработки и раскрытия
				персональных данных.
			</p>

			<h2>Сбор и использование информации</h2>
			<p>Мы собираем следующие типы данных:</p>
			<ul>
				<li>Идентификатор пользователя</li>
				<li>Идентификатор устройства</li>
				<li>История покупок</li>
				<li>Взаимодействие с приложением</li>
				<li>Информация о сбоях приложения</li>
				<li>Данные о производительности</li>
				<li>Адрес электронной почты</li>
				<li>Местоположение</li>
			</ul>
			<p>
				Эти данные используются для улучшения функциональности приложения, обработки
				платежей и аналитики.
			</p>

			<h2>Услуги сторонних сервисов</h2>
			<p>
				Для аналитики и обработки покупок мы используем следующие сторонние сервисы:
			</p>
			<ul>
				<li>
					<a href="https://www.google.com/policies/privacy/">
						Сервисы Google Play
					</a>
				</li>
				<li>
					<a href="https://firebase.google.com/policies/analytics">
						Google Analytics для Firebase
					</a>
				</li>
				<li>
					<a href="https://firebase.google.com/support/privacy/">
						Firebase Crashlytics
					</a>
				</li>
			</ul>
			<p>
				Эти службы могут собирать информацию, используемую для идентификации вашего
				устройства.
			</p>

			<h2>Безопасность и хранение данных</h2>
			<p>Мы обязуемся защищать и обеспечивать безопасность ваших данных.</p>

			<h2>Права пользователя и запрос на удаление учетной записи</h2>
			<p>
				Вы имеете право запросить удаление данных, связанных с вашим аккаунтом в
				приложении. Для этого отправьте запрос по адресу
				<a href="mailto:support@artifactum.io">support@artifactum.io</a>, указав в
				нем email пользователя, с которым вы зарегистрированы в приложении. Мы
				незамедлительно обработаем ваш запрос и завершим удаление данных в течение
				60 дней с момента получения запроса.
			</p>
			<p>
				Пожалуйста, имейте в виду, что некоторые данные могут быть сохранены в
				соответствии с требованиями закона даже после удаления ваших данных. Мы
				примем необходимые меры для обеспечения безопасности и конфиденциальности
				всех оставшихся данных.
			</p>

			<h2>Политика конфиденциальности для детей</h2>
			<p>
				Мы не предоставляем услуги для лиц моложе 13 лет. Мы сознательно не собираем
				личную информацию детей младше 13 лет. В случае если мы обнаружим, что
				ребенок младше 13 лет предоставил нам личную информацию, мы немедленно
				удалим ее с наших серверов. Если вы являетесь родителем или опекуном и вам
				известно, что ваш ребенок предоставил нам личную информацию, пожалуйста,
				свяжитесь с нами, чтобы мы могли предпринять необходимые действия.
			</p>

			<h2>Изменения политики конфиденциальности</h2>
			<p>
				Мы оставляем за собой право вносить изменения в нашу Политику
				конфиденциальности. Мы будем уведомлять вас о любых изменениях, публикуя
				новую Политику конфиденциальности на этой странице.
			</p>

			<h2>Согласие</h2>
			<p>
				Устанавливая и используя Приложение, вы соглашаетесь с условиями настоящей
				Политики конфиденциальности.
			</p>

			<h2>Контактная информация</h2>
			<p>
				Если у вас есть вопросы или предложения относительно нашей Политики
				конфиденциальности, пожалуйста, свяжитесь с нами по адресу поддержки
				(support@artifactum.io).
			</p>
			<div class="contact-info">
				<p>
					Email: <a href="mailto:support@artifactum.io">support@artifactum.io</a>
				</p>
			</div>
		</div>
	</div>

</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const lang = ref(navigator.language === "ru" ? "ru" : "en");
const isSelect = ref(false);
const isTerms = ref(false);

onMounted(() => {
	isSelect.value = false;
	lang.value = route?.query?.lang?.toString() || localStorage.getItem("lang") || "ru";
});
const showMenu = () => {
	isSelect.value = !isSelect.value;
};
</script>

<style lang="less">
.privacy-policy {}
</style>
