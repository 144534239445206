<!-- @format -->

<template>
	<header class="header-absolute">
		<div class="container-fluid header-container">
			<div class="row" style="width: 100%">
				<div class="col-2 logo-col">
					<logo />
				</div>

				<div class="col-10 nav-col">
					<div class="flex-block">
						<nav-menu class="header-menu">
							<li class="menu-item">
								<r-link route="about-app">{{ $t("menubar.About") }}</r-link>
							</li>
							<!-- <li class="menu-item"><r-link route="countries">Страны</r-link></li> -->
							<li class="menu-item">
								<r-Link route="partners">{{
									$t("menubar.To partners and authors")
								}}</r-Link>
							</li>
							<li class="menu-item">
								<r-Link route="feedback">{{ $t("menubar.Feedback") }}</r-Link>
							</li>

							<select-language @changeLang="onChangeLang" />

						</nav-menu>
						<nav-button class="header-button enter-button" v-if="visibleBtnCabinet">
							<img src="@assets/img/main/user.svg" alt="" />
							<span>{{ $t("partners.Personal account") }}</span>
						</nav-button>

						<nav-button class="header-button-sm enter-button" v-if="visibleBtnCabinet">
							<img src="@assets/img/nav-button/feedback.svg" alt="" />
							<span>{{ $t("feedback.Send") }}</span>
						</nav-button>
						<burger-icon />
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts" setup>
import { setLocale } from "@/lang/locale";
import Vue, { toRefs } from "vue";
import { RLink, NavMenu, NavButton, BurgerIcon, Logo } from "@/components";
const props = defineProps({ visibleBtnCabinet: { type: Boolean, default: false } });

function onChangeLang(lang: string) {
	setLocale(lang);
}
</script>

<style lang="css" scoped>
.header-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: transparent;
	z-index: 1;
}

.header-container {
	padding: 22px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-block {
	margin-left: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.logo-col {
	display: flex;
	justify-content: flex-start;
}

.nav-col {
	display: flex;
	justify-content: flex-end;
}

@media all and (max-width: 1350px) {
	.flex-block {
		width: 100%;
		margin-left: 30px;
	}
}

@media all and (max-width: 1150px) {
	.flex-block {
		width: auto;
		margin-left: 80px;
	}
}

@media all and (max-width: 505px) {
	.header-button-sm {
		display: none;
	}
}

@media all and (max-width: 400px) {
	.header-container {
		padding: 10px 15px;
	}
}

/* @media all and (min-width: 1920px) {
.flex-block {
  margin-left: calc((100vw + 100000px)/(1920 + 100000) * (70 + 5000) - 5000px);
}
} */
</style>
