<!-- @format -->

<template>
	<input
		:type="type"
		:value="value"
		@click="onClick"
		@input="onInput($event.target.value)"
		:name="name"
		:id="name"
		:placeholder="placeholder"
	/>
	<!-- :class="showValidation ? 'error' : 'success'" -->
</template>

<script lang="ts" setup>
import Vue, { ref, watch, defineProps, defineEmits } from "vue";
const props = defineProps({
	value: { type: String, required: true, default: "" },
	type: { type: String, required: true },
	regex: { type: String, required: true },
	name: { type: String, required: false, default: undefined },
	placeholder: { type: String, required: false, default: undefined },
	password: { type: Boolean, default: false },
	required: { type: Boolean, default: false },
	currentType: { ype: Boolean, default: false },
});
const emit = defineEmits<{ changeValue: [string]; input: [string] }>();

const innerValue = ref("");
const showValidation = ref(false);

const isValid = (): boolean => {
	if (props.required && (innerValue.value === undefined || innerValue.value === ""))
		return false;
	if (props.regex) return new RegExp(props.regex).test(innerValue.value);
	return true;
};
/**
 * Проверка валидности поля + отображение невалидности
 */
const checkValidation = (): boolean => {
	if (isValid()) showValidation.value = false;
	else showValidation.value = true;
	return !showValidation.value;
};

const onInput = (newValue: string) => {
	innerValue.value = newValue;
	emit("input", innerValue.value);
	checkValidation();
};

const onClick = () => {
	showValidation.value = false;
	checkValidation();
};
defineExpose({
	isValid,
	checkValidation,
});
</script>

<style></style>
