<!-- @format -->

<template>
	
	<div class="map-popup" v-if="marker?.point?.Count==1">
		<div class="btn-close">
			<img src="@assets/img/map-btn-close.svg" alt="" @click="onClosePopup" />
		</div>
		<img
			class="img"
			:src="
				correctedPreviewUrl ||
				'https://ic.pics.livejournal.com/muranochka/70733982/637675/637675_original.jpg'
			"
		/>
		<div class="title">{{ title || "Набережная Риги" }}</div>
		<div class="wrap">
			<div class="subtitle">{{ subTitle || "Прогулка по набережной" }} &nbsp;</div>
			<div class="more" @click="emit('more')">Узнать больше</div>
		</div>
		<!-- <h1>{{ message }}</h1>
		<div>{{ props.msg }}</div>
		<p>Count: {{ count }}</p> -->
		<!-- <button class="btn" @click="changeMessage">Изменить сообщение</button> -->
		<nav-button class="mx-auto btn" style="width: 90%" @click="emit('start')">
			<span>Начать</span>
		</nav-button>
	</div>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";

const onClosePopup = () => {
	emit("close");
};
const correctedPreviewUrl = computed(() => {
  return props.marker?.point?.MapRoute?.PreviewUrl?.replace('//files', '/files') || '';
});
// // Определение пропсов компонента
const props = defineProps({
	title: { type: String, default: "" },
	subTitle: { type: String, default: "" },
	img: { type: String, default: "" },
	marker: { type: Object },
});

// // Определение эмиттеров событий компонента
const emit = defineEmits<{
	close: [];
	start: [];
	more: []; // узнать больше
}>();

// // Отслеживание изменений пропса isCompact
// watch(
//   () => props.isCompact,
//   () => {
//     if (props.isCompact) {
//       console.log("Свойство isCompact поменялось на true");
//     }
//   }
// );

// const latitude = computed({
// get() {
// return lat.value || props.Latitude || 0;
// },
// set(newValue: number) {
// console.log(`newValue`, newValue);
// lat.value = newValue;
// },
// });
// // Блок переменных
// const count = ref(0); // Переменная для подсчета
// const message = ref(""); // Переменная для сообщения

// // Жизненный цикл компонента (при монтировании)
// onMounted(() => {
//   console.log("Компонент монтирован.");
// });

// // Вычисляемое свойство (двойное значение счетчика)
// const doubledCount = computed(() => count.value * 2);

// // Функция для увеличения счетчика
// function increment() {
//   count.value++;
// }

// // Функция для изменения сообщения и эмиттинга события обновления
function changeMessage() {
	// message.value = "Новое сообщение!";
	// emits("update:message", message.value);
}
</script>

<style lang="scss">
.map-popup {
	position: relative;
	padding-bottom: 10px;
	min-width: 408px;
	background: #282737;
	color: #fff;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0px 4px 15px 0px #00000033;

	.btn-close {
		position: absolute;
		// width: 40px;
		right: 10px;
		cursor: pointer;
		opacity: 0.6;
		// border: 1px solid #fff;
	}

	.img {
		width: 100%;
		max-height: 300px;
		overflow: hidden;
		border-radius: 8px 8px 0px 0px;
	}

	.title {
		padding: 10px 0px;
		font-size: 24px;
		font-weight: 700;
		line-height: 25px;
	}

	.wrap {
		display: flex;
		justify-content: center;
		padding: 0px 10px;

		.subtitle {
			width: 200px; // Ограничиваем ширину до 100px
			overflow: hidden; // Скрываем текст, выходящий за границы элемента
			white-space: nowrap; // Запрещаем перенос текста на новую строку
			text-overflow: ellipsis; // Добавляем многоточие в конце, если текст обрезается
			padding: 10px 0px 20px 0px;
			font-size: 15px;
			font-weight: 500;
			line-height: 25px;
		}

		.more {
			padding: 10px 0px 20px 0px;
			font-size: 15px;
			font-weight: 500;
			line-height: 25px;
			color: #5d58b7;
			cursor: pointer;
		}
	}
	.btn {
		text-transform: uppercase;
	}
}
</style>
