<template>
  <div class="policy-layout">
    <div class="container">
      <div id="nav-menu" class="nav-menu" :class="{ select: isSelect }">
        <div class="m1">
          <div class="title">
            <router-link to="/">
              <img src="@assets/img/logo/logo.svg" />&nbsp;<img src="@assets/img/logo/artifactum.svg" /></router-link>
          </div>
          <div class="menu-btn" id="menu-icon" @click="showMenu()">
            <img src="@assets/img/menu-svgrepo-com.svg" />
          </div>
        </div>
        <div class="m2">
          <div class="link" @click="	isSelect = false; router.push({ name: 'policy-privacy' })">
            <span v-if="lang != 'ru'">Privacy Policy </span>
            <span v-if="lang == 'ru'">{{ $t("footer.Privacy Policy") }}</span>
          </div>
          <div class="link" @click="isSelect = false; router.push({ name: 'policy-terms' })
            ">
            <span v-if="lang !== 'ru'">Terms & Conditions</span>
            <span v-if="lang === 'ru'">Условия использования</span>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter()
// // Определение пропсов компонента
// const props = defineProps({
//   isCompact: { type: Boolean, value: true },
//   msg: { type: String, default: "" },
// });

// // Определение эмиттеров событий компонента
// const emits = defineEmits<{
//   clickLogout: [], // Событие при клике на кнопку "Выход"
//   "update:message": [string]; // Событие обновления сообщения
// }>();

// // Отслеживание изменений пропса isCompact
// watch(
//   () => props.isCompact,
//   () => {
//     if (props.isCompact) {
//       console.log("Свойство isCompact поменялось на true");
//     }
//   }
// );

// const latitude = computed({
//   get() {
//     return lat.value || props.Latitude || 0;
//   },
//   set(newValue: number) {
//     console.log(`newValue`, newValue);
//     lat.value = newValue;
//   },
// });
// // Блок переменных
// const count = ref(0); // Переменная для подсчета
// const message = ref(""); // Переменная для сообщения

// // Жизненный цикл компонента (при монтировании)
// onMounted(() => {
//   console.log("Компонент монтирован.");
// });

// // Вычисляемое свойство (двойное значение счетчика)
// const doubledCount = computed(() => count.value * 2);

// // Функция для увеличения счетчика
// function increment() {
//   count.value++;
// }

// // Функция для изменения сообщения и эмиттинга события обновления
// function changeMessage() {
//   message.value = "Новое сообщение!";
//   emits("update:message", message.value);
// }
const route = useRoute();
const lang = ref(navigator.language === "ru" ? "ru" : "en");
const isSelect = ref(false);
const isTerms = ref(false);

onMounted(() => {
  isSelect.value = false;
  lang.value = route?.query?.lang?.toString() || localStorage.getItem("lang") || "ru";
});
const showMenu = () => {
  isSelect.value = !isSelect.value;
};
</script>

<style lang="scss">
.policy-layout {
  .container {
    line-height: 1.6;
    margin: 0;
    padding: 20px;
    /* max-width: 800px; */
    margin: auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }

  h1 {
    /* text-align: center; */
    font-size: 34px;
    color: #333;
  }

  h2 {
    font-size: 24px;
    color: #555;
    margin-top: 24px;
  }

  p,
  ul {
    margin: 10px 0;
    color: #333;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  .contact-info {
    margin-top: 20px;
  }

  .contact-info p {
    margin: 5px 0;
  }

  a {
    text-decoration: unset;
    color: #93b7d9;
  }

  .nav-menu {
    /* padding-top: 5px; */
    /* background: #e1d0d0; */
    margin-bottom: 30px;
    color: #ccc;
    overflow: hidden;
    min-height: 70px;
    max-height: 70px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .m2 {
      margin-top: 10px;
    }
  }

  .nav-menu .m1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-menu .m2 p {
    color: #ccc;
    margin-left: 20px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  .link {
    text-decoration: unset;
    color: #93b7d9;
    padding-left: 20px;
    font-size: 22px;
    cursor: pointer;
  }

  .container a:hover {
    text-decoration: underline;
  }

  .nav-menu {
    transition: 0.3s;

    &.select {
      transition: 0.3s;
      min-height: 160px;

      .menu-btn {
        border: 3px solid #000;
      }
    }

    .title {
      font-size: 28px;
      font-weight: 900;
      padding: 10px;
      margin-left: 10px;
      color: #fff;

      a:hover {
        text-decoration: unset;
      }
    }

    .menu-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: #ccc 1px solid;
      border-radius: 8px;
      cursor: pointer;
      background: #ffb83a;
      color: #fff;
      transition: 0.3s;
      margin-right: 10px;
      margin-top: 5px;

      img {
        width: 50%;
      }
    }
  }
}
</style>