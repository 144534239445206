<!-- @format -->

<template>
	<header class="header-absolute">
		<div class="container-fluid header-container">
			<div class="row" style="width: 100%">
				<div class="col-2 logo-col" style="position: relative;">
					<logo />
					<div style="z-index: 100"><ui-theme /></div>
					<div style="position: absolute; top:-8px;  font-size: .3em; text-transform: lowercase;">v:{{ ver }}</div>
				</div>

				<div class="col-10 nav-col">
					<div class="flex-block">
						<nav-menu class="header-menu">
							<li class="menu-item">
								<r-link route="about-app">{{ $t("menubar.About") }}</r-link>
							</li>
							<!-- <li class="menu-item"><r-link route="countries">Страны</r-link></li> -->
							<li class="menu-item">
								<r-link route="partners">{{
									$t("menubar.To partners and authors")
								}}</r-link>
							</li>
							<li class="menu-item">
								<r-link route="feedback">{{ $t("menubar.Feedback") }}</r-link>
							</li>

							<select-language @changeLang="onChangeLang" />
						</nav-menu>

						<nav-button class="header-button enter-button" v-if="isEnableCabinetBtn()" @click="emit('showCabinet')">
							<img src="@assets/img/main/user.svg" alt="" />
							<span>{{ $t("partners.Personal account") }}</span>
						</nav-button>

						<nav-button class="header-button-sm enter-button" v-if="isEnableCabinetBtn()" @click="emit('showCabinet')">
							<img src="@assets/img/main/user.svg" alt="" />
							<span>{{ $t("partners.Personal account") }}</span>
						</nav-button>

						<burger-icon class="violet" @click="emit('showMenu')" />
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { setLocale } from "@/lang/locale";
import i18n from "@/lang/locale";
const props = defineProps({ visibleBtnCabinet: { type: Boolean, default: false } });
const emit = defineEmits<{ showMenu: []; showCabinet: [] }>();
const route = useRoute();
const isEnableCabinetBtn = () => {
	return props.visibleBtnCabinet;
	//route.name == "partners" ||
};
const ver = ref("")
const onChangeLang = (lang: string) => {
	// i18n.global.locale = lang as any; // Изменение локали
	setLocale(lang);
};
onMounted(() => {
	loadSettings()
})
async function loadSettings() {
	try {
		// Запрашиваем файл appWebSettings.json из папки public
		const response = await fetch("/appWebSettings.json");

		// Проверяем, что запрос успешен
		if (!response.ok) {
			throw new Error("Не удалось загрузить настройки");
		}

		// Преобразуем ответ в JSON
		const settings = await response.json();

		// Сохраняем нужные параметры в переменные
		ver.value = settings.ver;
	} catch (error) {

		console.error("Ошибка при загрузке настроек:", error);
	}
}

</script>

<style lang="less">
.header-absolute {
	// background-color: var(--background-color);
	color: var(--text-color);

	header {
		position: relative;
		background-size: auto;
	}

	.header-container {
		padding: 22px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
	}

	.flex-block {
		margin-left: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.logo-col {
		display: flex;
		justify-content: flex-start;
	}

	.nav-col {
		display: flex;
		justify-content: flex-end;
	}

	@media all and (max-width: 1350px) {
		.flex-block {
			width: 100%;
			margin-left: 80px;
		}
	}

	@media all and (max-width: 1150px) {
		.flex-block {
			width: auto;
			margin-left: 30px;
		}
	}

	@media all and (max-width: 505px) {
		.header-button-sm {
			display: none;
		}
	}

	@media all and (max-width: 400px) {
		.header-container {
			padding: 10px 15px;
		}
	}

	.img-circle {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		max-height: 91px;
		overflow: hidden;
	}

	.img-circle img {
		max-width: 100%;
		height: auto;
	}

	.header-bg {
		position: absolute;
		top: 0;
		right: calc((100vw - 1350px) / (1920 - 1350) * (0 + 260) - 260px);
		z-index: -1;
		max-height: 91px;
		overflow: hidden;
	}

	@media all and (max-width: 1350px) {
		.header-bg {
			right: calc((100vw - 600px) / (1350 - 600) * (-220 + 380) - 380px);
		}

		.flex-block {
			width: 100%;
			margin-left: 27px;
		}
	}

	@media all and (max-width: 1150px) {
		.flex-block {
			width: auto;
		}
	}

	@media all and (max-width: 600px) {
		.header-bg {
			display: none;
		}
	}

	@media all and (max-width: 505px) {
		.img-circle {
			left: calc((100vw - 280px) / (505 - 280) * (0 + 100) - 100px);
		}

		.header-button-sm {
			display: none;
		}
	}

	@media all and (max-width: 400px) {
		.header-container {
			padding: 10px 15px;
		}
	}

	@media all and (min-width: 1921px) {
		.header-bg {
			right: 0;
		}
	}
}
</style>
