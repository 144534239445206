import { MapPointModel } from './MapPointModel';


export default class RouteItemClusterModel extends MapPointModel {
  constructor(obj?: RouteItemClusterModel) {
    super(obj);
    if (obj) Object.assign(this, obj);
  }

  /**
   * * Кол-во точек
   */
  Count: number = 0;
}
