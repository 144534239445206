<!-- @format -->

<template>
	<section class="enter-pa">
		<close-icon @click="emit('close')" />
		<div class="enter-block">
			<h3 class="enter-title">{{ t("Log In") }}</h3>

			<div class="tab-block">
				<div
					class="tab"
					v-for="(tab, index) in tabs"
					:key="index"
					@click="changeActiveTab(tab)"
					:class="{ activeTab: selectedTab === tab }"
				>
					{{ tab }}
				</div>
			</div>

			<div class="content-block">
				<div v-show="selectedTab == x1" class="form">
					<pos-input-label
						ref="refEmail"
						:label="t('feedback.Enter e-mail')"
						v-model:value="email"
						htmlClass=""
						name="email"
						type="email"
						placeholder="example@gmail.com"
						:required="true"
						:regex="emailRegex"
					>
						<svg width="12" height="12">
							<use xlink:href="@assets/img/svg-sprite.svg#email-input-icon"></use>
						</svg>
					</pos-input-label>

					<pos-input-label
						ref="refPassword"
						:label="t('feedback.Input password')"
						v-model:value="password"
						htmlClass=""
						name="password"
						:type="type[0].type"
						placeholder="*******"
						:required="true"
						:regex="passwordRegex"
					>
						<svg width="12" height="12">
							<use xlink:href="@assets/img/svg-sprite.svg#password-key-input"></use>
						</svg>
						<svg
							width="15"
							class="eye"
							v-if="type[0].type == 'password'"
							@click="currentType(0)"
							height="15"
						>
							<use xlink:href="@assets/img/svg-sprite.svg#password-eye-show"></use>
						</svg>
						<svg width="15" class="hide" v-else @click="currentType(0)" height="13">
							<use xlink:href="@assets/img/svg-sprite.svg#password-eye-hide"></use>
						</svg>
					</pos-input-label>

					<div class="flex-block">
						<form-button class="no-mt" @on-click="next()">{{
							t("To come in")
						}}</form-button>
						<div class="forget-password">{{ t("Advertising partner") }}</div>
					</div>
				</div>
				<div v-show="selectedTab == x2" class="form">
					<pos-input-label
						ref="refEmail2"
						:label="t('feedback.Enter e-mail')"
						v-model:value="email2"
						htmlClass=""
						name="email2"
						type="email"
						placeholder="example@gmail.com"
						:required="true"
						:regex="email2Regex"
					>
						<svg width="12" height="12">
							<use xlink:href="@assets/img/svg-sprite.svg#email-input-icon"></use>
						</svg>
					</pos-input-label>

					<pos-input-label
						ref="refPassword2"
						:label="t('feedback.Input password')"
						v-model:value="password2"
						htmlClass=""
						name="password2"
						:type="type[1].type"
						placeholder="*******"
						:required="true"
						:regex="password2Regex"
					>
						<svg width="12" height="12">
							<use xlink:href="@assets/img/svg-sprite.svg#password-key-input"></use>
						</svg>
						<svg
							width="15"
							class="eye"
							v-if="type[1].type == 'password'"
							@click="currentType(1)"
							height="15"
						>
							<use xlink:href="@assets/img/svg-sprite.svg#password-eye-show"></use>
						</svg>
						<svg width="15" class="hide" v-else @click="currentType(1)" height="13">
							<use xlink:href="@assets/img/svg-sprite.svg#password-eye-hide"></use>
						</svg>
					</pos-input-label>

					<div class="flex-block">
						<form-button class="no-mt" @on-click="next2()"
							>{{ t("To come in") }}
						</form-button>
						<div class="forget-password">{{ t("Forgot your password") }}</div>
					</div>
				</div>
			</div>
		</div>

		<popup-bg />
		<overlay />
	</section>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const refEmail = ref();
const refEmail2 = ref();
const refPassword = ref();
const refPassword2 = ref();
const emit = defineEmits<{ close: [] }>();
const selectedTab = ref("");
const tabs = ref<string[]>([]);
const x1 = ref<string>(t("Advertising partner"));
const x2 = ref<string>(t("Author"));
function changeActiveTab(tab: string) {
	selectedTab.value = tab;
}

function getStyle() {
	return {
		/* можно перечислять сssсвойства */
		// background: 'white',
		// 'border-left': '2px'
	};
}

onMounted(async () => {
	x1.value = t("Advertising partner");
	x2.value = t("Author");
	tabs.value = [x1.value, x2.value];
	selectedTab.value = x1.value;
});

const isClickedContinue = ref(false);
let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let passwordRegex: RegExp = /[\w\s\d]+$/;

let email2Regex: RegExp = emailRegex;
let password2Regex: RegExp = passwordRegex;

const email = ref("");
const password = ref("aaad1");
let email2: string = "";
let password2: string = "";

const type = ref([
	{ name: "password", type: "password" },
	{ name: "password2", type: "password" },
]);

function next() {
	isClickedContinue.value = true;
	const e1 = refEmail.value.checkValidation();
	const e2 = refPassword.value.checkValidation();
	if (!e1 || !e2) {
		return;
	}
}

function next2() {
	if (!refEmail2.value.checkValidation() && !refPassword2.value.checkValidation()) {
		return;
	}
}

function currentType(indexInput: number) {
	if (type.value[indexInput].type === "password") {
		type.value[indexInput].type = "text";
	} else {
		type.value[indexInput].type = "password";
	}
}
</script>

<style lang="css" scoped>
.enter-pa {
	padding: 0 15px;
	position: fixed;
	top: 0;
	right: 0;
	max-width: 625px;
	width: 100%;
	height: 100vh;
	z-index: 105;
	overflow-y: auto;
	-webkit-transform: translateX(625px);
	transform: translateX(625px);
	background-color: #fff;
	transition: all 0.5s linear;
}

.enter-pa.active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.enter-block {
	margin: 0 auto;
	margin-top: 125px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 400px;
}

.enter-title {
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 30px;
	line-height: 35px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	text-transform: uppercase;
	color: #282735;
}

.tab-block {
	margin-bottom: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 320px;
}

.tab {
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	color: #737373;
	cursor: pointer;
	position: relative;
	transition: all 0.2s ease;
}

.tab::after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	height: 2px;
	width: 0;
	background: #ffb83a;
	transition: all 0.2s ease;
}

.activeTab {
	color: black;
}

.activeTab::after {
	width: 100%;
}

.content-block {
	width: 100%;
}

.flex-block {
	margin-top: 47px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.forget-password {
	font-size: 16px;
	font-weight: 500;
	color: black;
	cursor: pointer;
	position: relative;
}

.forget-password::after {
	content: "";
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 1.5px;
	background: #3f3b98;
	transition: all 0.2s ease;
}

.forget-password:hover::after {
	width: 0;
}

@media all and (max-width: 330px) {
	.flex-block {
		flex-direction: column;
		align-items: flex-start;
	}

	.forget-password {
		margin-top: 15px;
	}
}
</style>
