<!-- @format -->

<template>
	<section class="section-share">
		<div class="share-content container-fluid">
			<div class="row">
				<div
					class="share-content-col col order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2"
				>
					<descr-block>
						<section-title class="ta-left title-center">
							<p
								:style="getStyle()"
								v-html="$t('homepage.CREATE AND SHARE YOUR OWN ROUTES')"
							></p>
						</section-title>

						<subtitle
							class="subtitle-center subtitle-share-lg"
							v-html="
								$t(
									'homepage.Ever wanted to make money on traveling? Now, the opportunity is yours!'
								)
							"
						/>
						<subtitle
							class="ta-center subtitle-share-sm"
							v-html="
								$t(
									'homepage.Ever wanted to make money on traveling? Now, the opportunity is yours!'
								)
							"
						/>
						<button-group />
					</descr-block>
				</div>

				<div
					class="share-content-col col order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1"
				>
					<share-block />
				</div>
			</div>
		</div>

		<div class="section-bg">
			<img src="@assets/img/share/circle-bg.png" alt="" />
		</div>
	</section>
</template>

<script lang="ts" setup>
import Vue from "vue";

function getStyle() {
	return {
		display: "block",
	};
}
</script>

<style lang="scss">
.share-content-col {
	padding: 0 15px;
	display: flex;
}

.share-content-col:first-child {
	flex: 0 0 48%;
	max-width: 48%;
}

.share-content-col:last-child {
	flex: 0 0 52%;
	max-width: 52%;
}

.section-share {
	padding-top: 93px;
	padding-bottom: 99px;
	/* background: var(--background-color-first); */
	background-position: right 5%;
	background-size: contain;
	position: relative;

	.section-bg {
		right: 0;
		left: unset;
		opacity: 0.4;
	}
}

.share-content {
	max-width: 1280px;
	display: flex;
	align-items: flex-start;
}

.section-bg {
	overflow: hidden;
	position: absolute;
	top: 20px;
	right: 10px;
	/* right: calc((100vw - 1300px) / (1920 - 1300) * (0 + 250) - 250px); */
	/* right: -270px; */
	z-index: -1;
}

@media all and (max-width: 992px) {
	.share-content-col:first-child {
		justify-content: center;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.share-content-col:last-child {
		justify-content: center;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.subtitle-center {
		margin: 0 auto;
		margin-top: 25px;
		text-align: center;
	}

	.share-content-col:first-child {
		margin-top: 62px;
	}
}

@media all and (max-width: 950px) {
	.section-share {
		padding-bottom: 63px;
		/* background: #f6f5ef; */
	}
}

@media all and (min-width: 1921px) {
	.section-bg {
		right: 0;
	}
}
</style>
