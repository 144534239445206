<!-- @format -->

<template>
	<popup class="form-popup active" v-if="visible">
		<popup-title>Смена пароля</popup-title>
		<pos-input-label
			:ref="refPassword4"
			label="Введите новый пароль"
			v-model="password4"
			htmlClass="form-item"
			name="password4"
			:type="type[0].type"
			placeholder="*******"
			:required="true"
			:regex="password4Regex"
		>
			<svg width="12" height="12">
				<use xlink:href="assets/img/svg-sprite.svg#password-key-input"></use>
			</svg>
			<svg
				width="15"
				class="eye"
				v-if="type[0].type == 'password'"
				@click="currentType(0)"
				height="15"
			>
				<use xlink:href="assets/img/svg-sprite.svg#password-eye-show"></use>
			</svg>
			<svg width="15" class="hide" v-else @click="currentType(0)" height="13">
				<use xlink:href="assets/img/svg-sprite.svg#password-eye-hide"></use>
			</svg>
		</pos-input-label>

		<pos-input-label
			:ref="refPassword5"
			label="Повторите пароль"
			v-model="password5"
			htmlClass="form-item"
			name="password5"
			:type="type[1].type"
			placeholder="*******"
			:required="true"
			:regex="password5Regex"
		>
			<svg width="12" height="12">
				<use xlink:href="assets/img/svg-sprite.svg#password-key-input"></use>
			</svg>
			<svg
				width="15"
				class="eye"
				v-if="type[1].type == 'password'"
				@click="currentType(1)"
				height="15"
			>
				<use xlink:href="assets/img/svg-sprite.svg#password-eye-show"></use>
			</svg>
			<svg width="15" class="hide" v-else @click="currentType(1)" height="13">
				<use xlink:href="assets/img/svg-sprite.svg#password-eye-hide"></use>
			</svg>
		</pos-input-label>

		<form-button @on-click="next4()">Сменить</form-button>
	</popup>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

const visible = ref(true);

const isClickedContinue = ref(false);
const password4Regex: RegExp = /[\w\s\d]+$/;
const password5Regex: RegExp = /[\w\s\d]+$/;

const type: {
	name: string;
	type: string;
}[] = [
	{ name: "password4", type: "password" },
	{ name: "password5", type: "password" },
];

const password4: string = "";
const password5: string = "";
const refPassword4 = ref();
const refPassword5 = ref();

onMounted(() => {
	let input = document.querySelectorAll('input[type="password"]');
});

const next4 = (value: string) => {
	isClickedContinue.value = true;
	//если проходит валидация
	if (!refPassword4.value.checkValidation() && !refPassword5.value.checkValidation()) {
		return;
	} else if (
		!refPassword4.value.checkValidation() ||
		!refPassword5.value.checkValidation()
	) {
		return;
	}
};

const currentType = (indexInput: number) => {
	// var eye = document.querySelectorAll('.eye4')
	// var hide = document.querySelectorAll('.hide4')

	if (type[indexInput].type == "password") {
		type[indexInput].type = "text";

		// for (let i = 0; i < eye.length; i++) {
		//     eye[i].classList.add('none')
		// }
		// for (let i = 0; i < hide.length; i++) {
		//     hide[i].classList.remove('none')
		// }
	} else {
		type[indexInput].type = "password";
		// for (let i = 0; i < eye.length; i++) {
		//     eye[i].classList.remove('none')
		// }
		// for (let i = 0; i < hide.length; i++) {
		//     hide[i].classList.add('none')
		// }
	}
};

const currentType2 = () => {
	// var eye2 = document.querySelectorAll('.eye2')
	// var hide2 = document.querySelectorAll('.hide2')

	if (type[1].type == "password") {
		type[1].type = "text";
		// for (let i = 0; i < eye2.length; i++) {
		//     eye2[i].classList.add('none')
		// }
		// for (let i = 0; i < hide2.length; i++) {
		//     hide2[i].classList.remove('none')
		// }
	} else {
		type[1].type = "password";
		// for (let i = 0; i < eye2.length; i++) {
		//     eye2[i].classList.remove('none')
		// }
		// for (let i = 0; i < hide2.length; i++) {
		//     hide2[i].classList.add('none')
		// }
	}
};
</script>

<style lang="css" scoped></style>
