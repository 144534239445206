<!-- @format -->

<template>
	<section class="video">
		<div class="container-fluid section-video-container">
			<div class="video-wrap" v-show="isLoadVideo">
				<video
					allow="fullscreen"
					frameBorder="0"
					width="100%"
					controls
					autoplay
					controlsList="nodownload"
				>
					<source :src="videoUrl()" />
				</video>
			</div>

			<video-block>
				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon1.svg" alt="" />
					</div>
					<template #title>
						{{ $t("about.Choose a route based on your interests") }}
					</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon2.svg" alt="" />
					</div>
					<template #title>
						{{
							$t("about.Participate in an exciting game with augmented reality elements")
						}}
					</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon3.svg" alt="" />
					</div>
					<template #title>
						{{ $t("about.Explore unknown and rediscover familiar places") }}
					</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon4.svg" alt="" />
					</div>
					<template #title> {{ $t("about.Feel like a treasure hunter") }}</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon5.svg" alt="" />
					</div>
					<template #title>{{
						$t("about.Gain access to related travel services")
					}}</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon6.svg" alt="" />
					</div>
					<template #title>
						{{ $t("about.Compete with other travelers in treasure hunting") }}
					</template>
				</video-item>

				<video-item>
					<div class="icon-video-item">
						<img src="@assets/img/features/features-icon7.svg" alt="" />
					</div>
					<template #title>{{ $t("about.Create your own routes") }}</template>
				</video-item>
			</video-block>

			<p class="video-text" v-html="$t('about.Artifactum is')"></p>
			<p class="video-text">
				{{ $t("about.You select a program") }}
			</p>
			<p class="video-text">
				{{ $t("about.The application") }}
			</p>
		</div>
	</section>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
const isLoadVideo = ref(false);

const videoUrl = (): string => {
	return location.host.indexOf("dev.") > -1
		? "https://dev-s3.artifactum.io/files/Video/landing.mp4?page=hsn#toolbar=0"
		: "https://s3.artifactum.io/files/Video/landing.mp4?page=hsn#toolbar=0";
};

onMounted(() => {
	setTimeout(() => {
		isLoadVideo.value = true;
	}, 50);
});
</script>

<style lang="less">
.video {
	padding-top: 46px;
	padding-bottom: 100px;
	position: relative;
	z-index: 5;
}

.video-wrap {
	margin-bottom: 50px;
}

/* .video-wrap::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #5D58B7;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      z-index: 0;
  } */

.video-wrap img {
	max-width: 100%;
	height: auto;
}

.play-wrap {
	padding: 4px;
	width: 78px;
	height: 78px;
	border-radius: 50%;
	border: 1px solid #5d58b7;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 1;
}

.icon-play {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #5d58b7;
	box-shadow: 0px 0px 44px #ffffff;
}

.section-video-container {
	max-width: 1250px;
}

.video-text {
	margin-top: 40px;
	font-weight: 500;
	font-size: 17px;
	line-height: 194%;
	color: var(--text-color-first);
}

.video-text strong {
	font-weight: 600;
	font-size: 18px;
}

.video-wrap:hover::after {
	opacity: 0.3;
	visibility: visible;
}

@media all and (max-width: 700px) {
	.play-wrap {
		width: 60px;
		height: 60px;
	}
}

@media all and (max-width: 450px) {
	.video-text {
		font-size: 16px;
	}
}

@media all and (max-width: 340px) {
	.video-text {
		font-size: 14px;
	}
}
</style>
