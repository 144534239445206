<!-- @format -->

<template>
	<section class="mobile-menu">
		<close-icon @click="emit('closeMenu')" />
		<nav id="mobile-menu">
			<ul class="mobile-list one">
				<li class="mobile-list-item">
					<r-link route="about-app" class="mobile-list-link">{{
						$t("menubar.About")
					}}</r-link>
				</li>
				<!-- <li class="mobile-list-item"><r-link route="countries" class="mobile-list-link">Страны</r-link></li> -->
				<!-- <li class="mobile-list-item"><r-link route="idea" class="mobile-list-link">Идея</r-link></li> -->
				<li class="mobile-list-item">
					<r-link route="partners" class="mobile-list-link">
						{{ $t("menubar.To partners and authors") }}
					</r-link>
				</li>
				<li class="mobile-list-item">
					<r-link route="feedback" class="mobile-list-link">
						{{ $t("menubar.Feedback") }}
					</r-link>
				</li>
				<li class="mobile-list-item">
					<r-link route="contacts" class="mobile-list-link">{{ $t("contacts") }}</r-link>
				</li>
			</ul>

			<select-lang-burger />
		</nav>
		<popup-bg />
	</section>
</template>

<script lang="ts" setup>
import Vue from "vue";
const emit = defineEmits<{ closeMenu: [] }>();
</script>

<style lang="less">
.mobile-menu {
	position: fixed;
	max-width: 400px;
	width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
	background-color: white;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	z-index: 101;
	overflow-y: auto;
	-webkit-transform: translateX(400px);
	transform: translateX(400px);
	-webkit-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.mobile-menu.active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: all 0.5s linear;
	transition: all 0.5s linear;
}
#mobile-menu {
	margin: 150px 0 50px 0;
	padding: 0 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.mobile-list {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.mobile-list-item {
	margin-bottom: 25px;
	-webkit-transform: translateX(-30px);
	transform: translateX(-30px);
	width: 100%;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.mobile-list-link {
	color: black;
	font-size: 17px;
	font-weight: 500;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.mobile-list-link:hover {
	color: black;
	-webkit-transform: translateX(10px);
	transform: translateX(10px);
}

.mobile-list-link:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -4px;
	background-color: black;
	height: 2px;
	width: 0;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.mobile-list-link.active {
	-webkit-transform: translateX(10px);
	transform: translateX(10px);
}
.mobile-list-link:hover:after,
.mobile-list-link.active:after {
	width: 100%;
}

.mobile-menu.active .mobile-list-item:nth-child(1) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}

.mobile-menu.active .mobile-list-item:nth-child(2) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 0.7s;
	transition-delay: 0.7s;
}

.mobile-menu.active .mobile-list-item:nth-child(3) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 0.8s;
	transition-delay: 0.8s;
}

.mobile-menu.active .mobile-list-item:nth-child(4) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 0.9s;
	transition-delay: 0.9s;
}

.mobile-menu.active .mobile-list-item:nth-child(5) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 1s;
	transition-delay: 1s;
}

.mobile-menu.active .mobile-list-item:nth-child(6) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 1.1s;
	transition-delay: 1.1s;
}

.mobile-menu.active .mobile-list-item:nth-child(7) {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition-delay: 1.2s;
	transition-delay: 1.2s;
}

.close-icon-in-mob-menu {
	width: 20px;
	height: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	cursor: pointer;
	z-index: 4;
	position: fixed;
	top: 5px;
	left: 5px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.close-icon-in-mob-menu:active,
.close-icon-in-mob-menu:hover {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

.close-icon-in-mob-menu span:nth-child(1) {
	width: 100%;
	height: 2px;
	-webkit-transform: rotate(-40deg) translateY(13px) translateX(-6px);
	transform: rotate(-45deg) translateY(6px) translateX(-7px);
	background: #9493a1;
}

.close-icon-in-mob-menu span:nth-child(2) {
	width: 100%;
	height: 2px;
	transform: rotate(45deg) translateY(-6px) translateX(-6.5px);
	background: #9493a1;
}

@media all and (max-height: 650px) {
	#mobile-menu {
		margin: 80px 0 50px 0;
	}
}
</style>
