<!-- @format -->

<template>
	<textarea
		:type="type"
		:value="value"
		@click="onClick"
		@input="onInput($event.target.value)"
		:name="name"
		:id="name"
		:placeholder="placeholder"
		:class="showValidation ? 'error' : 'success'"
	/>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from "vue";
const props = defineProps({
	value: { type: String, required: true },
	type: { type: String, required: true },
	name: { type: String, required: false, default: undefined },
	placeholder: { type: String, required: false, default: undefined },
	regex: { type: RegExp, required: false, default: undefined },
	password: { type: Boolean, default: false },
	required: { type: Boolean, default: false },
	htmlClass: { type: String },
	label: { type: String, default: undefined },
	isShowPassword: { type: Boolean, default: undefined },
});

const emit = defineEmits<{ changeValue: [string]; input: [string] }>();
const innerValue = ref("");
const inputRef = ref(null);
const showValidation = ref(false);

/**
 * Проверка валидности поля
 */
// watch(
// 	() => props.value,
// 	(newValue) => {
// 		console.log("value", props.value);
// 		emit("changeValue", props.value);
// 	}
// );

const isValid = (): boolean => {
	if (props.required && (innerValue.value === undefined || innerValue.value === ""))
		return false;
	if (props.regex) return new RegExp(props.regex).test(innerValue.value + "");
	return true;
};
/**
 * Проверка валидности поля + отображение невалидности
 */
const checkValidation = (): boolean => {
	if (isValid()) showValidation.value = false;
	else showValidation.value = true;
	return !showValidation.value;
};

const onInput = (newValue: string) => {
	innerValue.value = newValue;

	emit("input", innerValue.value);
	checkValidation();
};

const onClick = () => {
	showValidation.value = false;
	checkValidation();
};
defineExpose({
	isValid,
	checkValidation,
});
</script>

<style scoped></style>
