<!-- @format -->

<template>
	<div class="wrap-forms__form">
		<div class="wrap-forms__form-title">{{ title }}</div>
		<div class="form-flex-block">
			<pos-input-label
				:label="$t('homepage.City') "
				v-model:value="innderData.city"
				htmlClass=""
				name="city"
				type="name"
				:placeholder="$t('feedback.Name')"
				:required="true"
			>
				<svg width="12" height="12">
					<use xlink:href="@assets/img/svg-sprite.svg#name-user-icon"></use>
				</svg>
			</pos-input-label>

			<pos-input-label
				:label="$t('homepage.Name') "
				v-model:value="innderData.Name"
				htmlClass=""
				name="name"
				type="name"
				:placeholder="$t('feedback.Name')"
				:required="true"
			>
				<svg width="12" height="12">
					<use xlink:href="@assets/img/svg-sprite.svg#name-user-icon"></use>
				</svg>
			</pos-input-label>
			<pos-input-label
				ref="email5Ref"
				:label="$t('feedback.Enter e-mail')"
				v-model:value="innderData.email"
				htmlClass=""
				name="email"
				type="email"
				placeholder="example@gmail.com"
				:required="true"
				:regex="email5Regex"
			>
				<svg width="12" height="12">
					<use xlink:href="@assets/img/svg-sprite.svg#email-input-icon"></use>
				</svg>
			</pos-input-label>
			<div class="form-list">
				<ul>
					<slot> </slot>
				</ul>
			</div>
		</div>
		<div class="form-link">
			<div style="min-height: 40px">
				<a v-if="footer" href="#">{{ footer }}</a>
			</div>
			<form-button class="fb" @click="emits('click', innderData)">{{
				btnName
			}}</form-button>
		</div>
	</div>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";

// // Определение пропсов компонента
const props = defineProps({
	title: { type: String, default: "" },
	footer: { type: String, default: "" },
	btnName: { type: String, default: "Ok" },
});

const innderData = ref({ City: "", Name: "", email: "" });
// // Определение эмиттеров событий компонента
const emits = defineEmits<{
	click: [{ City: string; Name: string; email: string }]; //
}>();

// // Отслеживание изменений пропса isCompact
// watch(
//   () => props.isCompact,
//   () => {
//     if (props.isCompact) {
//       console.log("Свойство isCompact поменялось на true");
//     }
//   }
// );

// const latitude = computed({
// get() {
// return lat.value || props.Latitude || 0;
// },
// set(newValue: number) {
// console.log(`newValue`, newValue);
// lat.value = newValue;
// },
// });
// // Блок переменных
// const count = ref(0); // Переменная для подсчета
// const message = ref(""); // Переменная для сообщения

// // Жизненный цикл компонента (при монтировании)
// onMounted(() => {
//   console.log("Компонент монтирован.");
// });

// // Вычисляемое свойство (двойное значение счетчика)
// const doubledCount = computed(() => count.value * 2);

// // Функция для увеличения счетчика
// function increment() {
//   count.value++;
// }

// // Функция для изменения сообщения и эмиттинга события обновления
// function changeMessage() {
//   message.value = "Новое сообщение!";
//   emits("update:message", message.value);
// }
</script>

<style lang="scss">
.wrap-forms__form {
	margin-top: 38px;
	padding: 20px 30px 30px 30px;
	background: #fff;
	color: #455b66 !important;
	font-size: 15px;
	border-radius: 5px;
	// min-width: 400px;
	max-width: 400px;
	min-height: 714px;
	flex: 1;

	.wrap-forms__form-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 25px;
		text-align: center;
		margin-bottom: 30px;
		min-height: 50px;
	}

	.form-list {
		margin-top: 20px;
		min-height: 226px;

		ul,
		li {
			list-style-type: disc;
			margin-left: 8px;
			margin-bottom: 5px;

			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			text-align: left;

			&::marker {
				color: #ffb83a;
				/* Желтый цвет маркера */
			}
		}
	}

	.form-link {
		text-align: center;

		margin-top: auto;
		font-size: 15px;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		width: 100%;

		a {
			color: #5d58b7;
			text-decoration: underline;
		}

		.fb {
			min-height: 50px;
			min-width: 200px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 0px;
			margin-top: 10px;
		}
	}
}
</style>
