<!-- @format -->

<template>
	<layout>
		<fullpage class="min-h100vh">
			<header-static
				:visibleBtnCabinet="false"
				@showMenu="menuIsActive = true"
				@showCabinet="cabinetIsActive = true"
			/>

			<static-title>{{ $t("menubar.Feedback") }}</static-title>
			<section-feedback />

			<footer-default class="footer-absolute" />
		</fullpage>

		<overlay
			:class="{ show: menuIsActive || cabinetIsActive }"
			@click="menuIsActive = false"
		/>
		<burger-menu :class="{ active: menuIsActive }" @closeMenu="menuIsActive = false" />
		<section-enter
			:class="{ active: cabinetIsActive }"
			@close="cabinetIsActive = false"
		/>
		<!-- <section-forget-password /> -->

		<!-- <popup>
			<popup-img><img src="@assets/img/check.svg" alt="Ура" /></popup-img>
			<popup-text
				>Ваш email подтвержден, авторизуйтесь в приложении Artifactum</popup-text
			>
		</popup> -->

		<!-- <popup-change-password /> -->

		<!-- <popup-change-email /> -->
	</layout>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
const lang = ref("ru");
const menuIsActive = ref(false);
const cabinetIsActive = ref(false);

onMounted(() => {
	window.scrollTo({ top: 0, behavior: "smooth" });
});
</script>

<style lang="sass" scoped></style>
